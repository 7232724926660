import React, { useCallback, useContext, useEffect, useState } from "react";
import useApi from "../../../utils/restUtil";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import styles from "./BusinessCategories.module.css";
import { useTranslation } from "react-i18next";
import BusinessCategory from "./BusinessCategory";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { AppContext } from "../../../AppContext";
import Confirm from "../../common/Confirm";
import SearchBar from "../../common/search/SearchBar";

const BusinessCategories = () => {
  const { t } = useTranslation();
  const { get, del } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessCategory, setBusinessCategory] = useState({
    name: "",
    organization: user.role.code === "SU" ? "" : user.organization.id,
  });
  const [organizations, setOrganizations] = useState([]);
  const [searchText, setSearchText] = useState("");

  const loadBusinessCategories = useCallback(() => {
    showSpinner();
    get("/businessCategories", { name: { $regex: "^.*" + searchText + ".*", $options: "i" } })
      .then((resp) => {
        if (resp && resp.data) setBusinessCategories(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, searchText]);

  const deleteBusinessCategory = (id) => {
    showSpinner();
    del("/businessCategories/" + id)
      .then((resp) => {
        if (resp && resp.status === 204) {
          alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
          setBusinessCategory({ name: "", organization: user.role.code === "SU" ? "" : user.organization.id });
          loadBusinessCategories();
        }
      })
      .finally(hideSpinner);
  };

  const getOrganizations = useCallback(() => {
    showSpinner();
    get("/organizations")
      .then((resp) => {
        if (resp && resp.status === 200) {
          setOrganizations(resp.data);
        }
      })
      .catch((err) => {
        alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  }, [get, t]);

  useEffect(() => {
    if (user.role.code === "SU") {
      getOrganizations();
    }
  }, [getOrganizations, user]);

  useEffect(() => {
    loadBusinessCategories();
  }, [loadBusinessCategories]);

  return (
    <div>
      <div className="row mb-2">
        <div className={`col mt-3`}>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onClick={() => loadBusinessCategories()}
            onKeyPress={() => loadBusinessCategories()}
          />
        </div>
        <div className="col mt-3">
          <div className={`${styles["actionBtns"]}`}>
            <button
              className="btn btn-icon btn-secondary"
              data-toggle="modal"
              data-target="#businessCategoryModal"
              data-backdrop="static"
              data-keyboard="false"
            >
              <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
                <span className="btn-inner--text">{t("Business Category")}</span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="table-responsive">
              <div className="">
                <table className="table align-items-center">
                  <thead className="thead-light">
                    <tr>
                      <th>{t("No.")}</th>
                      <th>{t("Name")}</th>
                      {user.role.code === "SU" ? <th>{t("Organization")}</th> : ""}
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {businessCategories.map((businessCategory, idx) => {
                      return (
                        <tr key={businessCategory.id}>
                          <td>{idx + 1}</td>
                          <td>{businessCategory.name}</td>
                          {user.role.code === "SU" ? <td>{businessCategory.organization.name}</td> : ""}
                          <td>
                            <span
                              className={`fa fa-lg fa-edit mr-4`}
                              data-toggle="modal"
                              data-target="#businessCategoryModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={(e) => {
                                setBusinessCategory({
                                  ...businessCategory,
                                  organization: businessCategory.organization.id,
                                });
                              }}
                            ></span>
                            <span
                              className={`fa fa-lg fa-trash text-danger`}
                              data-toggle="modal"
                              data-target="#busCatDeleteModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={(e) => {
                                setBusinessCategory(businessCategory);
                              }}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BusinessCategory
        businessCategory={businessCategory}
        setBusinessCategory={setBusinessCategory}
        loadBusinessCategories={loadBusinessCategories}
        organizations={organizations}
      />
      <Confirm
        modalId="busCatDeleteModal"
        text={
          <>
            {t(`Are you sure you want to delete the business category`) + " "}
            <b>{businessCategory.name}</b>
          </>
        }
        onYesClick={() => deleteBusinessCategory(businessCategory.id)}
        level={ALERT_LEVEL.DANGER}
      />
    </div>
  );
};

export default BusinessCategories;
