import React, { useEffect, useState, useContext, useCallback } from "react";
import { ALERT_LEVEL } from "../../../constants";
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import styles from "./ReportBuilder.module.css";
import Section from "./sections/section/Section";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../AppContext";
import { useHistory } from "react-router";
import { saveAs } from "file-saver";
import { Types } from "../../formManagement/formBuilder/components/Types";

const ReportBuilder = () => {
  const { t } = useTranslation();
  const { post, put, get, del } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);
  const history = useHistory();

  const [businessCategories, setBusinessCategories] = useState([]);
  const [formCategories, setFormCategories] = useState([]);
  const [forms, setForms] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const [selectedBusinessCategory, setSelectedBusinessCategory] = useState();
  const [selectedFormCategory, setSelectedFormCategory] = useState();
  const [selectedForm, setSelectedForm] = useState();

  const [report, setReport] = useState({
    status: "draft",
    name: "",
    items: [],
    form: {},
    organization: "",
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const uploadFiles = useCallback(async () => {
    for (const [sectionIdx, section] of report.items.entries()) {
      for (const [fieldIdx, field] of section.items.entries()) {
        if (field.type === Types.IMAGE && field.answer && field.answer.file && !field.answer.isUploaded) {
          const formData = new FormData();
          formData.append("file", field.answer.file);
          const resp = await post("/reports/attachments", formData);

          if (resp && resp.data) {
            report.items[sectionIdx].items[fieldIdx].answer.name = resp.data.name;
            delete report.items[sectionIdx].items[fieldIdx].answer.file;
            report.items[sectionIdx].items[fieldIdx].answer.isUploaded = true;
          }
        }
      }
    }

    //bitlabs.ghjo 파일 업로드 후 Preview 이미지 깨지는 문제 수정
    await delay(3000);
    setReport({ ...report });

  }, [post, report]);

  const saveReport = useCallback(async () => {
    showSpinner();

    await uploadFiles();

    let http = post;
    if (report.id) http = put;

    http(`/reports${report.id ? `/${report.id}` : ""}`, report)
      .then((resp) => {
        //console.log('###### saveReport :: ', resp.resp )
        if (!report.id) setReport({ ...report, id: resp.data.id });
        alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
        if (report.status === "submitted") history.goBack();

      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [post, put, report, t, history, uploadFiles]);

  const deleteReport = () => {
    if (report.id) {
      showSpinner();
      del(`/reports/${report.id}`)
        .then((resp) => {
          history.push("/reportManagement/reports");
          alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(hideSpinner);
    } else {
      setReport({
        status: "draft",
        name: "",
        items: [],
        form: {},
        organization: "",
      });
      history.push("/reportManagement/reports");
    }
  };

  const exportReport = () => {
    showSpinner();
    get(`/reports/${report.id}/xlreport`, {}, { responseType: "blob" })
      .then((resp) => {
        // saveAs(resp.data, `${report.name}.xlsx`);
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); 
        const year = String(today.getFullYear()).slice(2); 
        const formattedDate = `${year}${month}${day}`;
  
        let fileName = `${formattedDate}_${report.name}`;
        if (report.status === "submitted") {
            fileName += "_submitted.xlsx";
        } else if (report.status === "draft") {
          fileName += "_draft.xlsx";
        } else {
          fileName += "_all.xlsx";
        }
        saveAs(resp.data, fileName);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  };

  const validateReport = () => {
    let isValid = true;
    report.items.forEach((section) => {
      section.items.forEach((field) => {
        if (field.config.required && !field.answer) isValid = false;
      });
    });
    return isValid;
  };
  const validateRequired = () => {
    alert(t("Please fill all fields marked as '*'"), ALERT_LEVEL.DANGER);
    report.items.forEach((section, sectionidx) => {
      section.items.forEach((field, fieldidx) => {
        let tempText = '';
        if (field.config.required && !field.answer) {
          tempText = 'ez-required' + sectionidx + fieldidx;
          document.getElementById(tempText).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }
      });
    });

  };
  useEffect(() => {
    showSpinner();
    get("/businessCategories")
      .then((resp) => {
        if (resp && resp.data) setBusinessCategories(resp.data);
        else setBusinessCategories([]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, user.role.code]);

  useEffect(() => {
    if (selectedBusinessCategory) {
      showSpinner();
      get("/formCategories", { businessCategory: selectedBusinessCategory })
        .then((resp) => {
          setSelectedFormCategory();
          if (resp && resp.data) setFormCategories(resp.data);
          else setFormCategories([]);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(hideSpinner);
    } else {
      setFormCategories([]);
      setSelectedFormCategory();
    }
  }, [selectedBusinessCategory, get, user.role.code]);

  useEffect(() => {
    if (selectedFormCategory) {
      showSpinner();
      get(`/forms`, { formCategory: selectedFormCategory })
        .then((resp) => {
          setSelectedForm();
          if (resp && resp.data && resp.data.length > 0) {
            setForms(resp.data);
          } else {
            setForms([]);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(hideSpinner);
    } else {
      setForms([]);
      setSelectedForm();
    }
  }, [selectedFormCategory, get, user.role.code]);

  useEffect(() => {
    if (selectedForm) {
      showSpinner();
      get(`/forms/` + selectedForm)
        .then((resp) => {
          if (resp && resp.data) {
            setReport((report) => {
              return { ...report, form: resp.data.id, items: resp.data.items, name: resp.data.name };
            });
          } else {
            setReport({
              status: "draft",
              name: "",
              items: [],
              form: {},
              organization: "",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(hideSpinner);
    } else {
      setReport({
        status: "draft",
        name: "",
        items: [],
        form: {},
        organization: "",
      });
    }
  }, [selectedForm, get, user, user.role.code]);

  useEffect(() => {
    if (user.role.code === "SU") {
      showSpinner();
      get("/organizations")
        .then((resp) => {
          if (resp && resp.status === 200) {
            setOrganizations(resp.data);
          }
        })
        .catch((err) => {
          alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
        })
        .finally(() => {
          hideSpinner();
        });
    }
  }, [user, get, t, user.role.code]);

  useEffect(() => {
    if (history.location.state && history.location.state.report) {
      const historyStateReport = history.location.state.report;
      setReport({
        ...historyStateReport,
        organization: historyStateReport.organization.id,
        createdBy: historyStateReport.createdBy.id,
      });
    }
  }, [history]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        //autosave값이 Y일 경우에만 실행됨
        user.autosave === "Y" &&
        // (user.role.code === "OP") && 
        report && report.name && !(report.readOnly || report.status !== "draft")) saveReport();
    }, 60000);
    return () => clearInterval(interval);
  }, [saveReport, report]);

  return (
    <div>
      {!report.id ? (
        <div className="card">
          <div className="card-header">
            <h3>{t("Choose form")}</h3>
          </div>
          <div className="card-body">
            
            <div className="row">
              <div className="form-group col-sm-12 col-md-4">
                <label className="form-control-label">{t("Business category")}</label>
                <select
                  className="form-control"
                  placeholder={t("Business category")}
                  value={selectedBusinessCategory}
                  onChange={(e) => {
                    setSelectedBusinessCategory(e.target.value);
                    const organization = businessCategories.find((busCat) => {
                      return busCat.id === e.target.value;
                    })?.organization;
                    setReport((report) => {
                      return { ...report, organization: organization?.id };
                    });
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {businessCategories.map((businessCategory) => {
                    return (
                      <option value={businessCategory.id} key={businessCategory.id}>
                        {businessCategory.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group col-sm-12 col-md-4">
                <label className="form-control-label">{t("Form category")}</label>
                <select
                  className="form-control"
                  placeholder={t("Form category")}
                  value={selectedFormCategory}
                  onChange={(e) => {
                    setSelectedFormCategory(e.target.value);
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {formCategories.map((formCategory) => {
                    return (
                      <option value={formCategory.id} key={formCategory.id}>
                        {formCategory.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <div className="form-group col-sm-12 col-md-4">
                <label className="form-control-label">{t("Form")}</label>
                <select
                  className="form-control"
                  placeholder={t("Form")}
                  value={selectedForm}
                  onChange={(e) => {
                    setSelectedForm(e.target.value);
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {forms.map((form) => {
                    return (
                      <option value={form.id} key={form.id}>
                        {form.name}
                      </option>
                    );
                  })}
                </select>
              </div> */}
            </div>
            {user.role.code === "SU" ? (
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <label className="form-control-label">{t("Organization")}</label>
                  <select
                    className="form-control"
                    placeholder={t("Organization")}
                    value={report.organization}
                    disabled
                    onChange={(e) => {
                      setReport((form) => {
                        return { ...form, organization: e.target.value };
                      });
                    }}
                  >
                    <option value={t("")}>{t("Select Business Category")}</option>
                    {organizations.map((organization) => {
                      return (
                        <option value={organization.id} key={organization.id}>
                          {organization.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {selectedBusinessCategory && selectedFormCategory && forms && forms.length ? (
            <>
              {/* 20221229KSW */}

              {report.form && report.items && report.items.length > 0 ?
                <div className="ez-wook">
                  <span></span>
                </div>
                : ""}
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table align-items-center">
                      <thead className="thead-light">
                        <tr>
                          <th>{t("Form")}</th>
                          <th>{t("Business category")}</th>
                          <th>{t("Form category")}</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {forms.map((form) => {
                          return (
                            <tr key={form.id}>
                              <td>
                                <ul className="nav nav-pills">
                                  <li
                                    className="nav-item"
                                    onClick={() => {
                                      setSelectedForm(form.id);
                                    }}
                                  >
                                    <span className={"nav-link " + (selectedForm === form.id ? "active" : "")}>
                                      <span className="nav-link-text">{form.name}</span>
                                    </span>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                {
                                  businessCategories.find((businessCat) => {
                                    return businessCat.id === selectedBusinessCategory;
                                  }).name
                                }
                              </td>
                              <td>
                                {
                                  formCategories.find((formCat) => {
                                    return formCat.id === selectedFormCategory;
                                  })?.name
                                }
                              </td>

                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {report.form && report.items && report.items.length > 0 ? (
        <div className="card">
          <div className="card-body">
            <>
              <div className="row mb-2 mt-4">
                <div className="col-12 mb-2">
                  <div className={`${styles["actionBtns"]}`}>
                    {/* 20230131KSW -마츠나미상 요청으로 가림 */}
                    {/* {user.role.code === "MA" && report.status === "submitted" ? (
                      <>
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            report.status = "approved";
                            report.verifiedBy = user.id;
                            setReport({ ...report });
                            saveReport();
                          }}
                        >
                          {t("Approve")}
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            report.status = "denied";
                            report.verifiedBy = user.id;
                            setReport({ ...report });
                            saveReport();
                          }}
                        >
                          {t("Deny")}
                        </button>
                      </>
                    ) : (
                      ""
                    )} */}
                    {report.status === "draft" ? (
                      !report.readOnly ? (
                        <>

                          <button
                            className="btn btn-secondary m-2"
                            onClick={() => {
                              report.status = "draft";
                              setReport({ ...report });
                              saveReport();
                            }}
                          >
                            {t("Save")}
                          </button>
                          <button
                            className="btn btn-success m-2"
                            onClick={() => {
                              if (validateReport()) {
                                report.status = "submitted";
                                setReport({ ...report });
                                saveReport();
                              } else {
                                validateRequired();
                              }
                            }}
                          >
                            {t("Submit")}
                          </button>
                          <button
                            className="btn btn-danger m-2"
                            hidden={true}
                            onClick={() => {
                              deleteReport();
                            }}
                          >
                            {t("Delete")}
                          </button>
                        </>
                      ) : (
                        ""
                      )
                    ) : ""}
                    <button
                        className="btn btn-primary  d-sm-block d-none"
                        onClick={() => {
                          exportReport();
                        }}
                      >
                        {t("Export")}
                      </button>
                  </div>
                </div>
              </div>
              <h1 className="d-flex justify-content-center mb-3">{report.name.toUpperCase()}</h1>
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-md-8">
                  {report.items?.map((section, idx) => {
                    return (
                      <Section key={idx} section={section} report={report} setReport={setReport} sectionIdx={idx} saveReport={saveReport} />
                    );
                  })}
                </div>
              </div>
            </>
          </div>
          <div className="card-footer">
            <div className="row mb-2 mt-4">
              <div className="col-12 mb-2">
                <div className={`${styles["actionBtns"]}`}>
                  {/* 20230131KSW 마츠나미상 요청으로 가림 */}
                  {/* {user.role.code === "MA" && report.status === "submitted" ? (
                    <>
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          report.status = "approved";
                          report.verifiedBy = user.id;
                          setReport({ ...report });
                          saveReport();
                        }}
                      >
                        {t("Approve")}
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          report.status = "denied";
                          report.verifiedBy = user.id;
                          setReport({ ...report });
                          saveReport();
                        }}
                      >
                        {t("Deny")}
                      </button>
                    </>
                  ) : (
                    ""
                  )} */}
                  {report.status === "draft" ? (
                    !report.readOnly ? (
                      <>
                        <button
                          className="btn btn-secondary m-2"
                          onClick={() => {
                            report.status = "draft";
                            setReport({ ...report });
                            saveReport();
                          }}
                        >
                          {t("Save")}
                        </button>
                        <button
                          className="btn btn-success m-2"
                          onClick={() => {
                            if (validateReport()) {
                              report.status = "submitted";
                              setReport({ ...report });
                              saveReport();
                            } else {
                              validateRequired()
                              // alert(t("Please fill all fields marked as '*'"), ALERT_LEVEL.DANGER);
                            }
                          }}
                        >
                          {t("Submit")}
                        </button>
                        <button
                          className="btn btn-danger m-2"
                          hidden={true}
                          onClick={() => {
                            deleteReport();
                          }}
                        >
                          {t("Delete")}
                        </button>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <button
                      className="btn btn-primary d-sm-block d-none"
                      onClick={() => {
                        exportReport();
                      }}
                    >
                      {t("Export")}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReportBuilder;
