import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Switch, useRouteMatch, Redirect } from "react-router-dom";
import Forms from "./forms/Forms";
import FormBuilder from "./formBuilder/FormBuilder";
import { PrivateRoute } from "../common/PrivateRoute";
import FormCategories from "./formCategories/FormCategories";

const FormManagement = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState("forms");
  const { path } = useRouteMatch();

  return (
    <div className="col container-fluid">
      <div className="mb-n-2">
        <ul className="nav nav-pills">
          <li className="nav-item" onClick={() => setActivePage("forms")}>
            <Link
              to={{ pathname: `${path}/forms`, state: { filter: {} } }}
              className={"nav-link " + (activePage === "forms" ? "active" : "")}
            >
              <span className="nav-link-text">{t("All Forms")}</span>
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActivePage("draftForms")}>
            <Link
              to={{ pathname: `${path}/forms`, state: { filter: { status: "draft" } } }}
              className={"nav-link " + (activePage === "draftForms" ? "active" : "")}
            >
              <span className="nav-link-text">{t("Draft Forms")}</span>
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActivePage("formCategories")}>
            <Link
              to={`${path}/formCategories`}
              className={"nav-link " + (activePage === "formCategories" ? "active" : "")}
            >
              <span className="nav-link-text">{t("Form Categories")}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="">
        <Switch>
          <PrivateRoute path={`${path}/forms`} component={Forms} />
          <PrivateRoute path={`${path}/formBuilder`} component={FormBuilder} />
          <PrivateRoute path={`${path}/formCategories`} component={FormCategories} />
          <Redirect to={`${path}/forms`} />
        </Switch>
      </div>
    </div>
  );
};

export default FormManagement;
