import React, { useContext, useEffect, useState } from "react";
import { ALERT_LEVEL } from "../constants";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { hideSpinner, showSpinner } from "./common/spinner/Spinner";
import { object, string, ref } from "yup";
import useApi from "../utils/restUtil";
import { useHistory } from "react-router-dom";
import { alert } from "./common/Alert";
import { AppContext } from "../AppContext";

const userSchema = object().shape({
  password: string()
    .required("Password is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPassword: string()
    .required("Please confirm your password")
    .when("password", {
      is: (password) => (password && password.length > 0 ? true : false),
      then: string().oneOf([ref("password")], "Password doesn't match"),
    }),
});

const ResetPassword = () => {
  const { t } = useTranslation();
  const { get, put } = useApi();
  const { token } = useParams();
  const { setAuth } = useContext(AppContext);
  const [user, setUser] = useState({
    id: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const history = useHistory();
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const submit = () => {
    userSchema
      .validate(user, { abortEarly: false })
      .then(() => {
        showSpinner();
        let http;
        if (user.id) http = put;
        http(`/users${user.id ? "/" + user.id : ""}`, user, token)
          .then((resp) => {
            if (resp && resp.status === 200) {
              history.push("/");
              alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
            }
          })
          .finally(() => {
            hideSpinner();
          });
      })
      .catch((validationErrors) => {
        validationErrors.inner.forEach((validationError) => {
          if (!Object.keys(error).includes(validationError.path)) {
            error[validationError.path] = validationError.message;
          }
        });
        setError({ ...error });
      });
  };

  useEffect(() => {
    showSpinner();
    if (token) {
      get("/auth/forgotPassword/" + token)
        .then((resp) => {
          if (resp && resp.data && resp.data.email) {
            setUser((user) => {
              return {
                ...user,
                email: resp.data.email,
                id: resp.data.id,
              };
            });
          }
        })
        .catch((e) => {
          alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
        })
        .finally(hideSpinner);
    }
  }, [token, get, t]);

  useEffect(() => {
    setAuth((auth) => {
      return { ...auth, token: token };
    });
  }, [setAuth, token]);

  return (
    <div className="main-content">
      <div className="container mt-8 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div className="card bg-secondary border-0 mb-0">
              <div className="card-body px-lg-5 py-lg-5">
                <h3 className="text-center mb-4">{t("Reset Password")}</h3>
                <div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("Email")}
                    defaultValue={user.email}
                    disabled
                  />
                </div>
                <form>
                  <div className="pt-3">
                    <div className="input-group input-group-merge input-group-alternative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t("Password")}
                        value={user.password}
                        onChange={(e) => {
                          delete error.password;
                          setError({ ...error });
                          setUser((user) => {
                            return { ...user, password: e.target.value };
                          });
                        }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <span
                            className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          ></span>
                        </span>
                      </div>
                    </div>
                    <p className="small text-danger m-0" hidden={!error.password}>
                      {t(error.password)}
                    </p>
                  </div>
                  <div className="pt-3">
                    <div className="input-group input-group-merge input-group-alternative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t("Confirm Password")}
                        value={user.confirmPassword}
                        onChange={(e) => {
                          delete error.confirmPassword;
                          setError({ ...error });
                          setUser((user) => {
                            return { ...user, confirmPassword: e.target.value };
                          });
                        }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <span
                            className={`fas ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"}`}
                            onClick={() => {
                              setShowConfirmPassword(!showConfirmPassword);
                            }}
                          ></span>
                        </span>
                      </div>
                    </div>
                    <p className="small text-danger m-0" hidden={!error.confirmPassword}>
                      {t(error.confirmPassword)}
                    </p>
                  </div>
                  <div className="text-center">
                    <button type="button" className="btn btn-primary mt-4" onClick={() => submit()}>
                      {t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
