import React from "react";
import { Types } from "../../components/Types";
import styles from "./Field.module.css";
import { useTranslation } from "react-i18next";

const Settings = ({ field, form, setForm }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`card ${styles.settings}`}
      draggable
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="card-body">
        <h3 className="card-title">{t("Settings")}</h3>
        <div className="row">
          <div className=" form-group col">
            <label className="form-control-label">{t("Label")}</label>
            <input
              type="text"
              className="form-control"
              value={field.text}
              onChange={(e) => {
                field.text = e.target.value;
                setForm({ ...form });
              }}
            />
          </div>
        </div>
        {/* 20220729KSW-form배경색추가 */}
        <div className="row">
          <div className=" form-group col">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id={field.linkId + "ez-bakColor"}
                checked={field.config.bakColor?true:false}
                onChange={(e) => {
                  field.config.bakColor = e.target.checked;
                  setForm({ ...form });
                }}
              />
              <label className="custom-control-label form-control-label" htmlFor={field.linkId + "ez-bakColor"}>
                {t("BackgroundColor")}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" form-group col">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id={field.linkId + "required"}
                checked={field.config.required?true:false}
                onChange={(e) => {
                  field.config.required = e.target.checked;
                  setForm({ ...form });
                }}
              />
              <label className="custom-control-label form-control-label" htmlFor={field.linkId + "required"}>
                {t("Required")}
              </label>
            </div>
          </div>
        </div>
        {[Types.RADIO, Types.DROPDOWN, Types.CHECKBOX].includes(field.type) ? (
          <div className="row">
            <div className=" form-group col">
              <label className="form-control-label">{t("Options")}</label>
              {field.config.answerOptions.map((option, idx) => {
                return (
                  <div className="d-flex pb-1">
                    <input
                      type="text"
                      className="form-control"
                      value={option}
                      onChange={(e) => {
                        field.config.answerOptions[idx] = e.target.value;
                        setForm({ ...form });
                      }}
                    />
                    <span
                      className="fas fa-trash align-self-center ml-2"
                      onClick={() => {
                        field.config.answerOptions.splice(idx, 1);
                        setForm({ ...form });
                      }}
                    ></span>
                  </div>
                );
              })}
              <div className="text-center mt-2">
                <button
                  className="btn btn-secondary align-self-center"
                  onClick={() => {
                    field.config.answerOptions.push("");
                    setForm({ ...form });
                  }}
                >
                  {t("Add")}
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {field.type === Types.NUMBER ? (
          <div className="row" style={{width:'270px',flexDirection:'column',marginBottom:'-25px'}}>
            <div className=" form-group col">
              <label className="form-control-label">{t("Unit")}</label>
              <select
                className="form-control"
                value={field.config.unit}
                onChange={(e) => {
                  field.config.unit = e.target.value;
                  setForm({ ...form });
                }}
              >
                <option value="">{t("Select one")}</option>
                <option value="°C">°C</option>
                <option value="Kg">Kg</option>
                <option value="¥">¥</option>
                <option value="$">$</option>
                <option value="%">%</option>
              </select>
            </div>
            
              <div className="form-group col">
                <label className="form-control-label">{t("Range")}</label>
                <div className="d-flex align-items-center">
                  <input
                    placeholder={t("Start")}
                    type="number"
                    className="form-control mr-2"
                    value={field.config.startValue}
                    onChange={(e) => {
                      field.config.startValue = e.target.value;
                      setForm({ ...form });
                    }}
                  />
                  <span className="mr-2">~</span>
                  <input
                    placeholder={t("End")}
                    type="number"
                    className="form-control"
                    value={field.config.endValue}
                    onChange={(e) => {
                      field.config.endValue = e.target.value;
                      setForm({ ...form });
                    }}
                  />
                </div>
              </div>
            
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Settings;
