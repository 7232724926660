import React, { useState } from "react";
import { Types } from "../../components/Types";
import Field from "../field/Field";
import styles from "./Section.module.css";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

const Section = ({ section, form, setForm, sectionIdx }) => {
  const { t } = useTranslation();
  const [isOver, setIsOver] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [isCollapsed, setCollapsed] = useState(false);

  const addField = (type, dropFieldIdx) => {
    let field;
    switch (type) {
      case Types.TEXT:
        field = { text: t("Text"), type: type, cellRef: "", config: {} };
        break;
      case Types.LONGTEXT:
        field = { text: t("Longtext"), type: type, cellRef: "", config: {} };
        break;
      case Types.DESCRIPTION:
        field = { text: t(""), type: type, cellRef: "", config: {} };
        break;
      case Types.NUMBER:
        field = { text: t("Number"), type: type, cellRef: "", config: {} };
        break;
      case Types.DATE:
        field = { text: t("Date"), type: type, cellRef: "", config: {} };
        break;
      case Types.TIME:
        field = { text: t("Time"), type: type, cellRef: "", config: {} };
        break;
      case Types.DROPDOWN:
        field = {
          text: t("Dropdown"),
          type: type,
          cellRef: "",
          config: {
            answerOptions: [t("Option 1"), t("Option 2")],
          },
        };
        break;
      case Types.RADIO:
        field = {
          text: t("Radio"),
          type: type,
          cellRef: "",
          config: {
            answerOptions: [t("Option 1"), t("Option 2")],
          },
        };
        break;
      case Types.CHECKBOX:
        field = {
          text: t("Checkbox"),
          type: type,
          cellRef: "",
          config: {
            answerOptions: [t("Option 1"), t("Option 2")],
          },
        };
        break;
      case Types.IMAGE:
        field = { text: t("Image"), type: type, cellRef: "", config: {} };
        break;
      default:
        break;
    }
    if (dropFieldIdx === 0 || dropFieldIdx) section.items.splice(dropFieldIdx, 0, field);
    else section.items.push(field);
    setForm({ ...form });
  };

  return (
    <>
      <div className={`card p-1 ${styles.card}`}>
        <div className={`card-header d-flex ${styles["card-header"]}`}>
          {editTitle ? (
            <OutsideClickHandler onOutsideClick={() => setEditTitle(false)}>
              <span>
                <textarea
                  style={{height:'80px',resize: 'none', width:'250%'}}
                  type="text"
                  className="form-control"
                  value={section.text}
                  onChange={(e) => {
                    section.text = e.target.value;
                    setForm({ ...form });
                  }}
                />
              </span>
            </OutsideClickHandler>
          ) : (
            <h3>
              {/* <span onClick={() => setEditTitle(true)}>{section.text}</span> */}
              <span onClick={() => setEditTitle(true)}>
                {section.text.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            </h3>
          )}
          <span className="flex-fill"></span>
          <span className="pr-7">
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                id={"repetable" + sectionIdx}
                className="custom-control-input"
                type="checkbox"
                value={true}
                onClick={(e) => {
                  section.repetable = e.target.checked;
                  setForm({ ...form });
                }}
              />
              <label htmlFor={"repetable" + sectionIdx} className="custom-control-label form-control-label">
                <span>{t("Repeats")}</span>
              </label>
            </div>
          </span>
          <span className={styles.actionBtns}>
            <span
              className={`fas fa-trash mr-4 text-danger`}
              onClick={(e) => {
                if (window.confirm(t("Are you sure you want to delete this section?\nOnce you delete and save, you won't be able to insert a new section in its place."))) { //섹션 삭제 시 한번 더 물음
                form.items.splice(sectionIdx, 1);
                setForm({ ...form });
              }
              }}
            ></span>
            <span
              data-toggle="collapse"
              href={"#collapse" + sectionIdx}
              role="button"
              aria-expanded="true"
              aria-controls={"collapse" + sectionIdx}
              className={`fas ${isCollapsed ? "fa-chevron-up" : "fa-chevron-down"}`}
              onClick={() => {
                setCollapsed(!isCollapsed);
              }}
            ></span>
          </span>
        </div>
        <div className="collapse show" id={"collapse" + sectionIdx}>
          <div
            className={`col-12 card-body droppable ${styles["card-body"]} ${styles.droplayer} 
            ${isOver ? styles.dropOver : ""}`}
            onDragOver={(e) => {
              e.preventDefault();
              if (e.dataTransfer.types.includes("add")) {
                setIsOver(true);
              }
            }}
            onDragLeave={() => {
              setIsOver(false);
            }}
            onDrop={(e) => {
              if (e.dataTransfer.types.includes("add")) {
                addField(e.dataTransfer.getData("add"));
              }
              setIsOver(false);
            }}
          >
            {section.items.map((field, idx) => {
              return (
                <Field
                  field={field}
                  form={form}
                  setForm={setForm}
                  key={idx}
                  addField={addField}
                  fieldIdx={idx}
                  sectionIdx={sectionIdx}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
