import React, { useState } from "react";
import { Types } from "./Types";
import styles from "./Components.module.css";
import { useTranslation } from "react-i18next";
const Description = () => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);

  return (
    <div
      className={`form-group draggable ${styles["form-group"]} ${isDragging ? styles["dragging"] : ""}`}
      draggable={true}
      onDragStart={(e) => {
        e.dataTransfer.setData("add", Types.DESCRIPTION);
        setIsDragging(true);
      }}
      onDragEnd={(e) => {
        setIsDragging(false);
      }}
    >
      <span className="fab fas fa-comments fa-3x d-block pl-2"></span>
      <label className="form-control-label">{t("Description")}</label>
    </div>
  );
};

export default Description;
