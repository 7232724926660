import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch, Switch, Redirect, useLocation } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { PrivateRoute } from "../common/PrivateRoute";
import Invites from "./invites/Invites";
import Organizations from "./organizations/Organizations";
import Users from "./users/Users";

const OrgManagement = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const location = useLocation();
  const { auth } = useContext(AppContext);

  return (
    <div className="col-12 container-fluid">
      <div className="row mb-n-2">
        <ul className="nav nav-pills">
          {auth.user.role.code === "SU" || auth.user.role.code === "AD" ? (
            <li className="nav-item">
              <Link
                to={`${path}/organizations`}
                className={"nav-link " + (location.pathname.includes("organizations") ? "active" : "")}
              >
                <span className="nav-link-text">{t("Organizations")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          <li className="nav-item">
            <Link to={`${path}/users`} className={"nav-link " + (location.pathname.includes("users") ? "active" : "")}>
              <span className="nav-link-text">{t("Users")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`${path}/invites`}
              className={"nav-link " + (location.pathname.includes("invites") ? "active" : "")}
            >
              <span className="nav-link-text">{t("Invites")}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-12">
          <Switch>
            <PrivateRoute path={`${path}/organizations`} component={Organizations} />
            <PrivateRoute path={`${path}/users`} component={Users} />
            <PrivateRoute path={`${path}/invites`} component={Invites} />
            {auth.user.role.code === "SU" || auth.user.role.code === "AD"  ? (
              <Redirect to={`${path}/organizations`} />
            ) : (
              <Redirect to={`${path}/users`} />
            )}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default OrgManagement;
