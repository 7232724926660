import React, { useCallback, useContext, useEffect, useState } from "react";
import useApi from "../../../utils/restUtil";
import { useHistory } from "react-router-dom";
import styles from "./Reports.module.css";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import { ALERT_LEVEL } from "../../../constants";
import { alert } from "../../common/Alert";
import { useTranslation } from "react-i18next";
import Confirm from "../../common/Confirm";
import { AppContext } from "../../../AppContext";
import { saveAs } from "file-saver";
import DataTable from 'react-data-table-component';
import Select from 'react-select';

const Reports = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { get, del } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [reports, setReports] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedReport, setSelectedReport] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchStore, setSearchStore] = useState("");
  const [searchWriter, setSearchWriter] = useState("");
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");
  const [filters, setFilters] = useState([]);
  const [formCategories, setFormCategories] = useState([]);
  const [tempPage, setTempPage] = useState(1);


  //DATATABLES
  const columns = [
    {
      name: t("No."),
      // cell: (row, index) => index + 1,
      cell: (row, index) => ((tempPage - 1) * perPage) + index + 1,
      maxWidth: '10px',
      sortable: false
    },
    {
      name: t("Name"),
      selector: row => row.name,
      sortable: false
    },
    {
      name: t("Store"),
      selector: row => row.createdBy?.store?.name,
      sortable: false
    },
    {
      name: t("Created at"),
      selector: row => row.createdAt ? new Date(row.createdAt)?.toLocaleString() : "",
      sortable: false,
      minWidth: '170px'
    },
    {
      name: t("Created by"),
      selector: row => row.createdBy?.name,
      sortable: false
    },
    {
      name: t("Updated at"),
      selector: row => row.updatedAt ? new Date(row.updatedAt)?.toLocaleString() : "",
      sortable: false,
      minWidth: '170px'
    },
    // {
    //     name: t("Updated by"),
    //     selector: row => row.updatedBy?.name,
    //     sortable : true
    // },
    // {
    //     name: t("Verified by"),
    //     selector: row => row.verifiedBy?.name,
    //     sortable : true
    // },
    {
      name: t("Status"),
      selector: row => t(row.status?.toUpperCase()),
      sortable: false
    },
    {
      name: t("Action"),
      cell: (row) => <>
        <i className="fas fa-lg fa-eye pr-2 " onClick={() => handleViewClick(row)}></i>
        {user.role.code !== "AD" ? (
          <i className="fas fa-lg fa-edit pr-2 " onClick={() => handleEditClick(row)}></i>
        ) : (
          ""
        )}
        <i className="fas fa-lg fa-trash text-danger"
          data-toggle="modal"
          data-target="#reportDeleteModal"
          data-backdrop="false"
          data-keyboard="false"
          onClick={() => handleDelClick(row)}
        ></i>
      </>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: '55px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const handleViewClick = (report) => {
    history.push(`/reportManagement/report`, {
      report: { ...report, readOnly: true },
    });
  }

  const handleEditClick = (report) => {
    history.push(`/reportManagement/report`, {
      report: { ...report },
    });
  }

  const handleDelClick = (report) => {
    setSelectedReport(report);
  }

  const onRowClicked = (report, event) => { 
      history.push(`/reportManagement/report`, {
        report: { ...report, readOnly: true },
      });
    event.stopPropagation();
  };


  const handlePageChange = page => {
    // console.log(`##  handlePageChange page:${page}, perPage:${perPage}`)

    setTempPage(page);
    getReports(page, perPage);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    // console.log('##  handlePerRowsChange ', newPerPage, page)
    setPerPage(newPerPage);
    setTimeout(() => { getReports(page, newPerPage); }, 100)

  }

  const getReports = useCallback((page, tempPerPage) => {
    showSpinner();


    // console.log("getReports :: searchToDate is ####",searchToDate)
    // console.log("getReports :: detail is ####",page,tempPerPage)
    let filter = {};
    // if (user.role.code === "AD") {
    //   filter = { ...filter, status: { $ne: "draft" } };
    // } else

    //ghjo.2022.08.21 add paggig parameter for table pagging
    filter = { ...filter, page: page, perPage: tempPerPage };

    if (user.role.code === "OP") {
      filter = { ...filter, createdBy: user.id };
    }
    if (searchText) {
      // filter = { ...filter, name: { $regex: "^.*" + searchText + ".*", $options: "i" } };
      filter = { ...filter, name: searchText };
    }
    if (searchStore) {
      filter = { ...filter, tStore: searchStore };
    }
    if (searchWriter) {
      filter = { ...filter, tWriter: searchWriter };
    }

    if (searchFromDate) {
      filter = { ...filter, 'updatedAt': { '$gte': searchFromDate } };
    }

    if (searchToDate) {
      filter = { ...filter, 'updatedAt': { '$lt': searchToDate } };
    }

    if (searchFromDate && searchToDate) {
      filter = { ...filter, 'updatedAt': { '$gte': searchFromDate, '$lt': searchToDate } };
    }

    if (history.location.state && history.location.state.filter && history.location.state.filter.status) {
      filter = { ...filter, status: history.location.state.filter.status };
    }

    setFilters(filter);

    // console.log('filter', filter)
    get(`/reports`, filter)
      .then((resp) => {
        // console.log('### res reports', resp.data)
        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {

          //resp.data.data.reverse();
          setReports(resp.data.data);
          setTotalRows(resp.data.total)
        } else setReports([]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, user, searchText, searchStore, searchWriter, searchFromDate, searchToDate, history]);

  const deleteReport = (id) => {
    showSpinner();
    del(`/reports/${id}`)
      .then((resp) => {
        getReports(tempPage, perPage);
        alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  };

  const exportReport = () => {
    showSpinner();
    get(`/reports/asdf/xlreports`, filters, { responseType: "blob" })
      .then((resp) => {
        // console.log(resp.data);
        // saveAs(resp.data, filters.name + `_total_search.xlsx`);
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); //날짜 두자리로 표시
        const month = String(today.getMonth() + 1).padStart(2, '0'); //월 두자리로 표시
        const year = String(today.getFullYear()).slice(2); // 연도 두자리
        const formattedDate = `${year}${month}${day}`;
  
        let fileName = formattedDate + "_" + filters.name;
        if (filters.status === "submitted") {
            fileName += "_submitted.xlsx";
        } else if (filters.status === "draft") {
          fileName += "_draft.xlsx";
        } else {
          fileName += "_all.xlsx";
        }
        saveAs(resp.data, fileName); 
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  };


  const NewDataList = () => {
    return (
      <div></div>
    );
  };

  const Select2 = (e) => (
    <Select
      options={formCategories}
      className="form-control form-control-sm pr-3"
      placeholder="Name"
      onChange={(e) => {
        setSearchText(e.value);
      }}
    />
  )

  const loadFormCategories = useCallback(() => {
    showSpinner();
    get("/formCategoriesName")
      .then((resp) => {
        if (resp && resp.data) setFormCategories(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get]);

  useEffect(() => {
    // console.log('test##')
    getReports(tempPage, perPage);
    loadFormCategories();
  }, [getReports, loadFormCategories, history.location.state, perPage, tempPage]);

  return (
    <div>

      <div className="row mb-2 d-none d-lg-flex">
        <div className={`col-8 mt-3`}>
          <div className={`form-group`}>
            <div className="input-group input-group-merge input-group-alternative">
              <Select2 />
              <input
                className="form-control"
                value={searchWriter.name}
                onChange={(e) => {
                  setSearchWriter(e.target.value);
                }}
                type="text"
                placeholder={t("Writer")} />
              <input
                className="form-control mx-2 rounded"
                value={searchFromDate.name}
                onChange={(e) => {
                  let temp = new Date(e.target.value).toISOString().slice(0, -8);
                  setSearchFromDate(temp);
                }}
                type="datetime-local"
                placeholder={t("Date From")} />
              <span style={{ alignSelf: "center" }}>~</span>
              <input
                className="form-control mx-2 rounded"
                value={searchToDate.name}
                onChange={(e) => {
                  let temp = new Date(e.target.value).toISOString().slice(0, -8);
                  // setSearchToDate(e.target.value);
                  setSearchToDate(temp);
                }}
                type="datetime-local"
                placeholder={t("Date To")} />

              <div className={`input-group-append`} >
                <span className="input-group-text">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={user.role.code !== "AD" ? "col-2 mt-3" : "col-2 mt-3"}>
          {user.role.code !== "AD" ? (
            <div className={`${styles["actionBtns"]}`}>
              <button
                className="btn btn-icon btn-secondary"
                onClick={(e) => {
                  history.push("/reportManagement/report");
                  e.stopPropagation();
                }}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-plus"></i>
                  <span className="btn-inner--text">{t("Report")}</span>
                </span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={user.role.code !== "AD" ? "col-2 mt-3" : "col-2 mt-3"}>
          {/* {filters.status === "submitted" && filters.name && (user.role.code === "AD" || user.role.code === "MA" || user.role.code === "OP") && Object.keys(reports).length > 0 ? ( */}
          {filters.name && (user.role.code === "AD" || user.role.code === "MA" || user.role.code === "OP") && Object.keys(reports).length > 0 ? (  
            <button
              // className="btn btn-primary"
              className={
                filters.status === "submitted"
                  ? "btn btn-submitted"
                  : filters.status === "draft"
                  ? "btn btn-draft"
                  : "btn btn-primary"
              }
              onClick={() => {
                exportReport();
              }}
            >
              {t("Export-")}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mb-2 d-flex d-sm-none"> 
      <div className="col-12">

      {user.role.code !== "AD" ? (
        <div className={`${styles["actionBtns"]}`}>
          <button
            className="btn btn-icon btn-secondary"
            onClick={(e) => {
              history.push("/reportManagement/report");
              e.stopPropagation();
            }}
          >
            <span className="btn-inner--icon">
              <i className="fas fa-plus"></i>
              <span className="btn-inner--text">{t("Report")}</span>
            </span>
          </button>
        </div>
      ) : (
        ""
      )}
      
      </div>
      </div>
      <div className="card">
        <div className="card-body  ">
          <div className="row">
            {reports && reports.length > 0 ?
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={reports}
                  customStyles={customStyles}
                  striped
                  highlightOnHover
                  pointerOnHover
                  onRowClicked={onRowClicked}
                  pagination
                  paginationServer
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                //paginationRowsPerPageOptions = {[10, 15, 20, 25, 30, 100]}
                />
              </div>
              :
              <div className="w-100 text-center">
                {t("There are no records to display")}
              </div>
            }

          </div>
        </div>
      </div>

      <Confirm
        modalId="reportDeleteModal"
        text={
          <>
            {t(`Are you sure you want to delete the report`) + " "}
            <b>{selectedReport.name}</b>
          </>
        }
        onYesClick={() => deleteReport(selectedReport.id)}
        level={ALERT_LEVEL.DANGER}
      />
    </div>
  );
};

export default Reports;
