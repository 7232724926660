import React from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import moment from 'moment';
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";


const Organization = ({ organization, setOrganization, loadOrganizations }) => {
  const { t } = useTranslation();
  const { post, put } = useApi();

  const saveOrganization = () => {

    // console.log('saveOragainzation', organization)
   

    showSpinner();
    let http = post;
    if (organization._id) http = put;
    http(`/organizations${organization._id ? "/" + organization._id : ""}`, organization)
      .then((resp) => {
        if (resp && resp.status === 200) {
          alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
          $("#organizationModal").modal("hide");
          reset();
          loadOrganizations();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409)
          alert(t("Organization with same name already exists!"), ALERT_LEVEL.DANGER);
        else alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  };

  const reset = () => {
    setOrganization({ name: "", startContract:'', expireContract:'', email:'', phone:'', grade:'', paymentMethod:'',status:'' });
  };

  const gradeMap = [
    {grade:'A'},
    {grade:'B'},
    {grade:'C'}
    // {grade:'A',user:'50 more'  , db:'1gb more', price:'150,000'},
    // {grade:'B',user:'50 less'  , db:'1gb less', price:'150,000'},
    // {grade:'C',user:'10 less'  , db:'500mb less', price:'150,000'}
  ]

  const paymentMethods = [
    'month',
    'year'
  ]

  return (
    <div
      className="modal fade"
      id="organizationModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="organizationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="organizationModalLabel">
              {t(`${organization.id ? "Edit" : "Add"} Organization`)}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={reset}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <label className="form-control-label">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Name")}
                value={organization.name}
                onChange={(e) => {
                  setOrganization({ ...organization, name: e.target.value });
                }}
              />
            </div>
            {/* 대표 email */}
            <div className="">
              <label className="form-control-label">{t("Email")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Email")}
                value={organization.email}
                onChange={(e) => {
                  setOrganization({ ...organization, email: e.target.value });
                }}
              />
            </div>
             {/* 대표 전화번호 */}
             <div className="">
              <label className="form-control-label">{t("Phone")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Phone")}
                value={organization.phone}
                onChange={(e) => {
                  setOrganization({ ...organization, phone: e.target.value });
                }}
              />
            </div>
            {/* 계약 시작일 */}
            <div className="" >
              <label className="form-control-label">{t("Start Contact")}</label>
              <input type="date" 
                    className="form-control" 
                    placeholder={t("Start Contact")} 
                    value={organization.startContract?moment(organization.startContract).format('YYYY-MM-DD'):''}
                    onChange={(e) => {
                      if(e.target.value){
                        //let value = ;
                        //console.log(`date : ${value}`)
                        setOrganization({ ...organization, startContract: moment(e.target.value).format('YYYYMMDD') });
                      }
                    }}
                />
            </div>
             {/* 계약 만료일 */}
            <div className="" >
              <label className="form-control-label">{t("Expire Contact")}</label>
              <input type="date" 
                     className="form-control" 
                     placeholder={t("Expire Contact")}
                     value={organization.expireContract?moment(organization.expireContract).format('YYYY-MM-DD'):''}
                     onChange={(e) => {
                      if(e.target.value){
                        //let value = moment(e.target.value).format('YYYYMMDD');
                        setOrganization({ ...organization, expireContract: moment(e.target.value).format('YYYYMMDD') });
                      }
                    }}
                />
            </div>
            {/* 사 용 등 급 (사용유저수,사용중인 DB용ㄹㅇ,사용중인데이터량,사용료*/}
            <div className="">
              <label className="form-control-label">{t("Grade")}</label>
              <select className="form-control"
                      value={organization.grade}
                      onChange={(e) => {
                      setOrganization({ ...organization, grade: e.target.value });
                      }}
                >
                <option value="">{t("Select one")}</option>
                {gradeMap.map((item, idx) => {
                  return (
                    <option key={idx} value={item['grade']}>
                      {item['grade']}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* 지불방식 */}
            <div className="">
              <label className="form-control-label">{t("Payment")}</label>
              <select className="form-control"
                      value={organization.paymentMethod}
                      onChange={(e) => {
                        setOrganization({ ...organization, paymentMethod: e.target.value });
                       }} >
                <option value="">{t("Select one")}</option>
                {paymentMethods.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* 사용제한 */}
            <div className="">
              <label className="form-control-label">{t("Use Status")}</label>
              <select className="form-control"
                      value={organization.useStatus}
                      onChange={(e) => {
                        setOrganization({ ...organization, useStatus: e.target.value });
                       }} >
                <option value="">{t("Select one")}</option>
                <option key={1} value={'ACTIVE'}> USE </option>
                <option key={2} value={'INACTIVE'}> NOT USE </option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={reset}>
              {t("Cancel")}
            </button>
            <button type="button" className="btn btn-primary" onClick={saveOrganization}>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organization;
