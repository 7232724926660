import React, { useEffect, useState } from "react";
import useApi from "../utils/restUtil";
import { alert } from "./common/Alert";
import { ALERT_LEVEL } from "../constants";
import { hideSpinner, showSpinner } from "./common/spinner/Spinner";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { object, string, ref } from "yup";

const userSchema = object().shape({
  username: string().required("Username is required").min(6, "Username must be atleast 6 characters"),
  name: string().required("Name is required"),
  password: string()
    .required("Password is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPassword: string()
    .required("Please confirm your password")
    .when("password", {
      is: (password) => (password && password.length > 0 ? true : false),
      then: string().oneOf([ref("password")], "Password doesn't match"),
    }),
});

const SignUp = () => {
  const { t } = useTranslation();
  const { get, post } = useApi();
  const history = useHistory(); 
  const { token } = useParams();

  const [user, setUser] = useState({
    username: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: {},
    store: {},
    organization: {},
    autosave: "Y"
  });
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const signIn = () => {
    userSchema
      .validate(user, { abortEarly: false })
      .then(() => {
        let userToSend = { ...user, role: user.role.id, organization: user.organization.id };
        if (user.store?.id) userToSend.store = user.store.id;
        showSpinner();
        post("/auth/signup", userToSend)
          .then((resp) => {
            if (resp && resp.data && resp.data.user) {
              history.push("/signin");
              alert(t("Registered successfully. Please login with your credentials"), ALERT_LEVEL.SUCCESS);
            }
          })
          .catch((e) => {
            if (e.response && e.response.status === 409)
              alert(t("Username/Email is already registered!"), ALERT_LEVEL.DANGER);
            else alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
          })
          .finally(hideSpinner);
      })
      .catch((validationErrors) => {
        validationErrors.inner.forEach((validationError) => {
          if (!Object.keys(error).includes(validationError.path)) {
            error[validationError.path] = validationError.message;
          }
        });
        setError({ ...error });
        console.log(error);
        alert(t("Error validating data!"), ALERT_LEVEL.DANGER);
      });
  };

  useEffect(() => {
    showSpinner();
    if (token) {
      get("/invites/token/" + token)
        .then((resp) => {
          if (resp && resp.data) {
            setUser((user) => {
              return {
                ...user,
                email: resp.data.email,
                organization: resp.data.organization,
                role: resp.data.role,
                store: resp.data.store
              };
            });
          }
        })
        .catch((e) => {
          alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
        })
        .finally(hideSpinner);
    }
  }, [token, get, t]);

  return (
    <div className="main-content">
      <div className="container mt-4 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div className="card bg-secondary border-0 mb-0">
              <div className="card-body px-lg-5 py-lg-5">
                <h3 className="text-center mb-4">{t("Sign Up")}</h3>
                <div>
                  <label className="form-control-label">{t("Email")}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("Email")}
                    defaultValue={user.email}
                    disabled
                  />
                </div>
                <div className="pt-3">
                  <label className="form-control-label">{t("Role")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Role")}
                    defaultValue={user.role.name}
                    disabled
                  />
                </div>
                <div className="pt-3">
                  <label className="form-control-label">{t("Organization")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Organization")}
                    defaultValue={user.organization?.name}
                    disabled
                  />
                </div>
                {user.role?.code === "OP" || user.role?.code === "MA" ? (
                  <div className="pt-3">
                    <label className="form-control-label">{t("Store")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("Store")}
                      defaultValue={user.store.name}
                      disabled
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="pt-3">
                  <label className="form-control-label">{t("Username")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Username")}
                    value={user.username}
                    onChange={(e) => {
                      delete error.username;
                      setError({ ...error });
                      setUser((user) => {
                        return { ...user, username: e.target.value };
                      });
                    }}
                  />
                  <p
                    className="small text-info m-0"
                    hidden={error.username === "Username must be atleast 6 characters"}
                  >
                    {t("Username must be atleast 6 characters")}
                  </p>
                  <p className="small text-danger m-0" hidden={!error.username}>
                    {t(error.username)}
                  </p>
                </div>
                <div className="pt-3">
                  <label className="form-control-label">{t("Name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Name")}
                    value={user.name}
                    onChange={(e) => {
                      delete error.name;
                      setError({ ...error });
                      setUser((user) => {
                        return { ...user, name: e.target.value };
                      });
                    }}
                  />
                  <p className="small text-danger m-0" hidden={!error.name}>
                    {t(error.name)}
                  </p>
                </div>
                <div className="pt-3">
                  <label className="form-control-label">{t("Password")}</label>
                  <div className="input-group input-group-merge input-group-alternative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder={t("Password")}
                      value={user.password}
                      onChange={(e) => {
                        delete error.password;
                        setError({ ...error });
                        setUser((user) => {
                          return { ...user, password: e.target.value };
                        });
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <span
                          className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        ></span>
                      </span>
                    </div>
                  </div>
                  {/* 20230614 경고문 추가 */}
                  <p
                    className="small text-info m-0"
                    hidden={error.username === "Username must be atleast 6 characters"}
                  >
                    {t("Password must contain at least 8 characters, 1 uppercase letter, 1 number, and 1 special character.")}
                  </p>
                  <p className="small text-danger m-0" hidden={!error.password}>
                    {t(error.password)}
                  </p>
                </div>
                <div className="pt-3">
                  <label className="form-control-label">{t("Confirm Password")}</label>
                  <div className="input-group input-group-merge input-group-alternative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control"
                      placeholder={t("Confirm Password")}
                      value={user.confirmPassword}
                      onChange={(e) => {
                        delete error.confirmPassword;
                        setError({ ...error });
                        setUser((user) => {
                          return { ...user, confirmPassword: e.target.value };
                        });
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <span
                          className={`fas ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"}`}
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                        ></span>
                      </span>
                    </div>
                  </div>
                  <p className="small text-danger m-0" hidden={!error.confirmPassword}>
                    {t(error.confirmPassword)}
                  </p>
                </div>
                <div className="text-center">
                  <button type="button" className="btn btn-primary mt-4" onClick={signIn}>
                    {t("Sign up")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
