import React, { useCallback, useContext, useEffect, useState } from "react";
import useApi from "../../../utils/restUtil";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import styles from "./Regions.module.css";
import { useTranslation } from "react-i18next";
import Region from "./Region";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { AppContext } from "../../../AppContext";
import Confirm from "../../common/Confirm";
import SearchBar from "../../common/search/SearchBar";

const Regions = () => {
  const { t } = useTranslation();
  const { get, del } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState({ name: "", organization: user.role.code === "SU" ? "" : user.organization.id });
  const [organizations, setOrganizations] = useState([]);
  const [searchText, setSearchText] = useState("");

  const loadRegions = useCallback(() => {
    showSpinner();
    get("/regions", { name: { $regex: "^.*" + searchText + ".*", $options: "i" } })
      .then((resp) => {
        if (resp && resp.data) setRegions(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, searchText]);

  const deleteRegion = (id) => {
    showSpinner();
    del("/regions/" + id)
      .then((resp) => {
        if (resp && resp.status === 204) {
          alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
          setRegion({ name: "", organization: user.role.code === "SU" ? "" : user.organization.id });
          loadRegions();
        }
      })
      .finally(hideSpinner);
  };

  const getOrganizations = useCallback(() => {
    showSpinner();
    get("/organizations")
      .then((resp) => {
        if (resp && resp.status === 200) {
          setOrganizations(resp.data);
        }
      })
      .catch((err) => {
        alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  }, [get, t]);

  useEffect(() => {
    if (user.role.code === "SU") {
      getOrganizations();
    }
  }, [getOrganizations, user]);

  useEffect(() => {
    loadRegions();
  }, [loadRegions]);

  return (
    <div>
      <div className="row mb-2">
        <div className={`col mt-3`}>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onClick={() => loadRegions()}
            onKeyPress={() => loadRegions()}
          />
        </div>
        <div className="col mt-3">
          <div className={`${styles["actionBtns"]}`}>
            <button
              className="btn btn-icon btn-secondary"
              data-toggle="modal"
              data-target="#regionModal"
              data-backdrop="static"
              data-keyboard="false"
            >
              <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
                <span className="btn-inner--text">{t("Region")}</span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="table-responsive">
              <div className="">
                <table className="table align-items-center">
                  <thead className="thead-light">
                    <tr>
                      <th>{t("No.")}</th>
                      <th>{t("Name")}</th>
                      {user.role.code === "SU" ? <th>{t("Organization")}</th> : ""}
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {regions.map((region, idx) => {
                      return (
                        <tr key={region.id}>
                          <td>{idx + 1}</td>
                          <td>{region.name}</td>
                          {user.role.code === "SU" ? <td>{region.organization?.name}</td> : ""}
                          <td>
                            <span
                              className={`fa fa-lg fa-edit mr-4`}
                              data-toggle="modal"
                              data-target="#regionModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={(e) => {
                                setRegion({
                                  ...region,
                                  organization: region.organization.id,
                                });
                              }}
                            ></span>
                            <span
                              className={`fa fa-lg fa-trash text-danger`}
                              data-toggle="modal"
                              data-target="#regionDeleteModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={(e) => {
                                setRegion(region);
                              }}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Region region={region} setRegion={setRegion} loadRegions={loadRegions} organizations={organizations} />
      <Confirm
        modalId="regionDeleteModal"
        text={
          <>
            {t(`Are you sure you want to delete the region`) + " "}
            <b>{region.name}</b>
          </>
        }
        onYesClick={() => deleteRegion(region.id)}
        level={ALERT_LEVEL.DANGER}
      />
    </div>
  );
};

export default Regions;
