import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../AppContext";
import { ALERT_LEVEL } from "../../../constants";
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import Confirm from "../../common/Confirm";
import SearchBar from "../../common/search/SearchBar";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";

const Users = () => {
  const { t } = useTranslation();
  const { get, del } = useApi();
  const { auth } = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchText, setSearchText] = useState("");

  const loadUsers = useCallback(() => {
    showSpinner();
    get(`/users`, { name: { $regex: "^.*" + searchText + ".*", $options: "i" } })
      .then((resp) => {
        if (resp && resp.data) setUsers(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, searchText]);

  const deleteUser = (id) => {
    showSpinner();
    del("/users/" + id)
      .then((resp) => {
        if (resp && resp.status === 200) {
          alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
          loadUsers();
        }
      })
      .finally(hideSpinner);
  };

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);
  return (
    <div>
      <div className="row mb-2">
        <div className={`col-6 mt-3`}>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onClick={() => loadUsers()}
            onKeyPress={() => loadUsers()}
          />
        </div>
        {/* <div className="col-12 mb-2">
          <div className={`${styles["actionBtns"]}`}>
            <button
              className="btn btn-icon btn-secondary"
              data-toggle="modal"
              data-target="#userModal"
              data-backdrop="static"
              data-keyboard="false"
            >
              <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
                <span className="btn-inner--text">{t("User")}</span>
              </span>
            </button>
          </div>
        </div> */}
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="table-responsive">
              <div className="">
                <table className="table align-items-center">
                  <thead className="thead-light">
                    <tr>
                      <th>{t("No.")}</th>
                      <th>{t("Name")}</th>
                      <th>{t("Email")}</th>
                      <th>{t("Role")}</th>
                      <th>{t("Store")}</th>
                      <th>{t("Organization")}</th>
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {users.map((user, idx) => {
                      return (
                        <tr key={user.id}>
                          <td>{idx + 1}</td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.role.name}</td>
                          <td>{user.store?.name}</td>
                          <td>{user.organization?.name}</td>
                          <td>
                            {auth.user.id === user.id ? (
                              ""
                            ) : (
                              <span
                                className={`fa fa-lg fa-trash text-danger`}
                                data-toggle="modal"
                                data-target="#userDeleteModal"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={(e) => {
                                  setSelectedUser(user);
                                }}
                              ></span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        modalId="userDeleteModal"
        text={
          <>
            {t(`Are you sure you want to delete the user`) + " "}
            <b>{selectedUser.name}</b>
          </>
        }
        onYesClick={() => deleteUser(selectedUser.id)}
        level={ALERT_LEVEL.DANGER}
      />
    </div>
  );
};

export default Users;
