import React from "react";
import { useTranslation } from "react-i18next";

const Confirm = ({ modalId, text, level, onYesClick = () => {}, onNoClick = () => {}, onClose = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="inviteModalLabel">
              {t(`Please confirm`)}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">{text}</div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onNoClick}>
              {t("No")}
            </button>
            <button
              type="button"
              className={`btn ${level ? "btn-" + level : "btn-primary"}`}
              data-dismiss="modal"
              onClick={onYesClick}
            >
              {t("Yes")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
