export const Types = {
  TEXT: "text",
  DATE: "date",
  LONGTEXT: "longtext",
  TIME: "time",
  DROPDOWN: "dropdown",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  IMAGE: "image",
  NUMBER: "number",
  TEMPERATURE: "temparature",
  DESCRIPTION: "description",
};
