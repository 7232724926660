import React, { useRef } from "react";
import InfoSection from "./section/InfoSection";
import Section from "./section/Section";
import { useTranslation } from "react-i18next";

const Sections = ({ form, setForm }) => {
  const { t } = useTranslation();
  const addButtonRef = useRef();
  const addNewSection = () => {
    form.items.push({
      text: t("Section"),
      repetable: false,
      items: [],
    });
    setForm({ ...form });
  };

  return (
    <div className="section">
      <InfoSection form={form} setForm={setForm} />
      {form.items?.map((section, idx) => {
        return <Section section={section} form={form} setForm={setForm} key={idx} sectionIdx={idx} />;
      })}
      <div className="text-center" ref={addButtonRef}>
        <button className="btn btn-primary" onClick={addNewSection}>
          {t("Add new section")}
        </button>
      </div>
    </div>
  );
};

export default Sections;
