import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import $ from "jquery";

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { auth } = useContext(AppContext);
  // console.log("tests : ##"  ,auth)
  return (
    <nav
      className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
      id="sidenav-main"
    >
      <div className="scrollbar-inner">
        <div className="sidenav-header align-items-center">
          <Link className="navbar-brand" to="/" style={{ width: "85%" }}>
            <h2 style={{ whiteSpace: "break-spaces" }}>{t("FDES")}</h2>
          </Link>
          <span
            className={"pr-2 d-xl-none d-lg-none d-md-none"}
            onClick={() => {
              $("#sidenav-main").toggleClass("active");
            }}
          >
            <span className="fas fa-times"></span>
          </span>
        </div>
        <div className="navbar-inner">
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to={`/dashboard`}
                  className={"nav-link " + (location.pathname.includes("dashboard") ? "active" : "")}
                  onClick={() => {
                    $("#sidenav-main").toggleClass("active");
                  }}
                >
                  <i className="fas fa-tachometer-alt text-primary"></i>
                  <span className="nav-link-text">{t("Dashboard")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/reportManagement`}
                  className={"nav-link " + (location.pathname.includes("reportManagement") ? "active" : "")}
                  onClick={() => {
                    $("#sidenav-main").toggleClass("active");
                  }}
                >
                  <i className="fas fa-table text-warning"></i>
                  <span className="nav-link-text">{t("Reports")}</span>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  to={`/connection`}
                  className={"nav-link " + (location.pathname.includes("connection") ? "active" : "")}
                >
                  <i className="fas fa-link text-info"></i>
                  <span className="nav-link-text">{t("Schedules")}</span>
                </Link>
              </li> */}
              {auth.user.role.code === "SU" || auth.user.role.code === "AD" || auth.user.role.code === "MA" ? (
                <li className="nav-item d-none d-xl-block d-lg-block d-md-block">
                  <Link
                    to={`/formManagement`}
                    className={"nav-link " + (location.pathname.includes("forms") ? "active" : "")}
                    onClick={() => {
                      $("#sidenav-main").toggleClass("active");
                    }}
                  >
                    <i className="fas fa-file-alt text-default"></i>
                    <span className="nav-link-text">{t("Forms")}</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {auth.user.role.code === "SU" || auth.user.role.code === "AD" ? (
                <li className="nav-item">
                  <Link
                    to={`/storeManagement`}
                    className={"nav-link " + (location.pathname.includes("storeManagement") ? "active" : "")}
                    onClick={() => {
                      $("#sidenav-main").toggleClass("active");
                    }}
                  >
                    <i className="fa fa-store text-success"></i>
                    <span className="nav-link-text">{t("Stores")}</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {auth.user.role.code === "SU" || auth.user.role.code === "AD" ? (
                <li className="nav-item">
                  <Link
                    to={`/orgManagement`}
                    className={"nav-link " + (location.pathname.includes("orgManagement") ? "active" : "")}
                    onClick={() => {
                      $("#sidenav-main").toggleClass("active");
                    }}
                  >
                    <i className="fas fa-users text-purple"></i>
                    <span className="nav-link-text">{t("Users")}</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
