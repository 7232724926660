import React, { useCallback, useEffect, useState , useContext} from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../AppContext";
import { ALERT_LEVEL } from "../../../constants";
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import Confirm from "../../common/Confirm";
import SearchBar from "../../common/search/SearchBar";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import Organization from "./Organization";
import styles from "./Organizations.module.css";

const Organizations = () => {
  const { t } = useTranslation();
  const { get, del } = useApi();
  const { auth } = useContext(AppContext);

  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState({ name: "" });
  const [searchText, setSearchText] = useState("");

  const loadOrganizations = useCallback(() => {
    showSpinner();
    get(`/organizations`, { name: { $regex: "^.*" + searchText + ".*", $options: "i" } })
      .then((resp) => {
        if (resp && resp.data) setOrganizations(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, searchText]);

  const deleteOrganization = (id) => {
    showSpinner();
    del("/organizations/" + id)
      .then((resp) => {
        if (resp && resp.status === 200) {
          alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
          setSelectedOrganization({ name: "" });
          loadOrganizations();
        }
      })
      .finally(hideSpinner);
  };

  const styleAlert = { fontWeight: "bold", color:"red"}

  useEffect(() => {
    loadOrganizations();
  }, [loadOrganizations]);
  return (
    <div>
      <div className="row mb-2">
        <div className={`col mt-3`}>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onClick={() => loadOrganizations()}
            onKeyPress={() => loadOrganizations()}
          />
        </div>
        <div className="col mt-3">
        {auth.user.role.code === "SU"?
          <div className={`${styles["actionBtns"]}`}>
            <button
              className="btn btn-icon btn-secondary"
              data-toggle="modal"
              data-target="#organizationModal"
              data-backdrop="static"
              data-keyboard="false"
            >

              <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
                <span className="btn-inner--text">{t("Organization")}</span>
              </span>
            </button>
          </div>:''}
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="table-responsive">
              <div className="">
                <table className="table align-items-center">
                  <thead className="thead-light">
                    <tr>
                      <th>{t("No.")}</th>
                      <th>{t("Name")}</th>
                      <th>{t("Start")}</th>
                      <th>{t("Expire")}</th>
                      <th>{t("Grade")}</th>
                      <th>{t("User")}</th>
                      <th>{t("Report")}</th>
                      <th>{t("File")}</th>
                      <th>{t("Email")}</th>
                      <th>{t("Phone")}</th>
                      {/* <th>{t("Price")}</th> */}
                      {/* <th>{t("Payment")}</th> */}
                      <th>{t("Status")}</th>
                      {auth.user.role.code === "SU"?<th>{t("Action")}</th>:''}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {organizations.map((organization, idx) => {
                      return (
                        <tr key={organization.id}>
                          <td>{idx + 1}</td>
                          <td>{organization.name}</td>
                          <td>{organization.startContract}</td>
                          <td>{organization.expireContract}</td>
                          <td>{organization.grade}</td> {/* grade */}
                          <td><span style={organization.userAlert?styleAlert:{}}>{`${organization.user} / ${organization.userGrade}`}</span></td> {/* user count */}
                          <td><span style={organization.reportAlert?styleAlert:{}}>{`${organization.report} / ${organization.reportGrade}`}</span></td> {/* report count */}
                          <td><span style={organization.diskAlert?styleAlert:{}}>{`${organization.disk} MB / ${organization.diskGrade} MB`}</span></td> {/* file count */}
                          <td>{organization.email}</td> {/* email */}
                          <td>{organization.phone}</td> {/* phone */}
                          {/* <td>{organization.price}¥</td>  */}
                          {/* <td>{organization.paymentMethod}</td>  */}
                          <td>{organization.useStatus ==='INACTIVE'?'NOT USE':'USE'}</td> {/* status */}
                          {auth.user.role.code === "SU"?
                          (<td>
                            <span
                              className={`fa fa-lg fa-edit mr-4`}
                              data-toggle="modal"
                              data-target="#organizationModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={(e) => {
                                setSelectedOrganization(organization);
                              }}
                            ></span>
                            <span
                              className={`fa fa-lg fa-trash text-danger`}
                              data-toggle="modal"
                              data-target="#orgDeleteModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={(e) => {
                                setSelectedOrganization(organization);
                              }}
                            ></span>
                          </td>):''}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Organization
        organization={selectedOrganization}
        setOrganization={setSelectedOrganization}
        loadOrganizations={loadOrganizations}
      />
      <Confirm
        modalId="orgDeleteModal"
        text={
          <>
            {t(`Are you sure you want to delete the organization `)}
            <b>{selectedOrganization.name}</b>
          </>
        }
        onYesClick={() => deleteOrganization(selectedOrganization._id)}
        level={ALERT_LEVEL.DANGER}
      />
    </div>
  );
};

export default Organizations;
