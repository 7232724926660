import React from "react";
import Scrollbars from "react-custom-scrollbars";
import Date from "./Date";
import LongText from "./LongText";
import Text from "./Text";
import styles from "./Components.module.css";
import Time from "./Time";
import Dropdown from "./Dropdown";
import Radio from "./Radio";
import Checkbox from "./Checkbox";
import { useTranslation } from "react-i18next";
import Image from "./Image";
import Number from "./Number";
import Description from "./Description";

const Components = () => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.components}`}>
      <div className={`card ${styles.card}`}>
        <div className="card-header" style={{ textAlign: "center" }}>
          <h3>{t("Components")}</h3>
        </div>
        <Scrollbars
          style={{ width: "100%", height: "80vh" }}
          renderThumbVertical={(props) => <div {...props} className="scroll-thumb-vertical black" />}
          renderThumbHorizontal={(props) => <div {...props} className="scroll-thumb-horizontal black" />}
          autoHide
        >
          <div className="card-body" style={{ textAlign: "center" }}>
            <Description />
            <Text />
            <LongText />
            <Number />
            <Date />
            <Time />
            <Dropdown />
            <Radio />
            <Checkbox />
            <Image />
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default Components;
