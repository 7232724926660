import React, { useState } from "react";

import styles from "./Spinner.module.css";

let showSpinner;
let hideSpinner;
const Spinner = () => {
  const [spinnerCount, setSpinnerCount] = useState(0);
  showSpinner = () => {
    setSpinnerCount((prevState) => {
      return prevState + 1;
    });
  };
  hideSpinner = () => {
    setSpinnerCount((prevState) => {
      if (prevState > 0) return prevState - 1;
      else return 0;
    });
  };
  return (
    <div
      className={styles.overlay}
      id="loading"
      style={{
        display: spinnerCount <= 0 ? "none" : "block",
        opacity: 0.8,
        zIndex: 999999,
      }}
    >
      <div className={styles.overlayInner}>
        <div className={styles.overlayContent}>
          {/* <img src={spinner} width="50px" alt="Loading..." /> */}
          <div className={styles.loader}></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
export { showSpinner, hideSpinner };
