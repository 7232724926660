import React from "react";
import { useTranslation } from "react-i18next";

const SearchBar = ({ searchText, setSearchText, onClick, onKeyPress }) => {
  const { t } = useTranslation();
  return (
    <div className={`form-group`}>
      <div className="input-group input-group-merge input-group-alternative">
        <input
          className={`form-control`}
          placeholder={t("Search")}
          value={searchText.name}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onKeyPress();
            }
          }}
        />
        <div className={`input-group-append`} onClick={onClick}>
          <span className="input-group-text">
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
