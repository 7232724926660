import React, { useContext, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import useApi from "../../utils/restUtil";
import { ALERT_LEVEL } from "../../constants";
import { hideSpinner, showSpinner } from "../common/spinner/Spinner";
import { alert } from "../common/Alert";
import ForgotPasswordModal from "./../ForgotPasswordModal";
import ProfileModal from './ProfileModal';
const Profile = () => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);
    const { get } = useApi();
    const [profile, setProfile] = useState({
        name: "",
        email: "",
        username: "",
        autosave:"" 
    });

    // 20240103추가 
    // useEffect(()=>{
    //     const autosave = "Y";
    //     setAutoSaveEnabled(autosave === "Y");
    // },[]);

    // const [isAutoSaveEnabled, setAutoSaveEnabled] = useState(true);

   

    // const handleAutoSaveToggle = () => {
    //     setAutoSaveEnabled(!isAutoSaveEnabled);
    //   };

    const loadProfile = useCallback(() => {
        showSpinner();
        get("/users", { _id: auth.user.id })
            .then((resp) => {
                if (resp && resp.data && (resp.data.length !== 0)) setProfile(
                    {
                        // ...profile,
                        organizationName:resp.data[0].organization.name,
                        name: resp.data[0].name,
                        username: resp.data[0].username,
                        email: resp.data[0].email,
                        autosave : resp.data[0].autosave
                    });
                // console.log("로드", resp.data);
            })
            .catch((e) => {
                if (e.response && e.response.status === 409)
                    alert(t("Username is already registered!"), ALERT_LEVEL.DANGER);
                else alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
            })
            .finally(hideSpinner);
    }, [get, auth.user.id, t]);

    // const updateUser = () => {
    //     showSpinner();
    //     let http = put;
    //     http(`/users/profile${auth.user ? "/" + auth.user.id : ""}`, profile)
    //         .then((resp) => {
    //             if (resp && resp.status === 200) {
    //                 alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
    //                 loadProfile();
    //             }
    //         })
    //         .catch((e) => {
    //             if (e.response && e.response.status === 409)
    //                 alert(t("Username is already registered!"), ALERT_LEVEL.DANGER);
    //             else alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
    //         })
    //         .finally(() => {
    //             hideSpinner();
    //             // reset();
    //         });
    // };
    // const reset = () => {
    //     setChangeUser({
    //         username: auth.user.username,
    //         name: auth.user.name,
    //         email: auth.user.email,
    //     });
    // };
    useEffect(() => {
        loadProfile();
    }, [loadProfile]);


    return (
        <>
            <div className="col-12 container-fluid">
                <div className="card">
                    <div className={`card-body`}>
                        {/* <div className="col-xl-6 col-md-12"> */}
                        <div className="m-auto col-8  text-center border shadow">
                            <div className="row p-3 text-lg">
                                <div className="col-12 border-bottom pb-2 text-center my-3"><i className="fa fa-user mr-2"></i>{t("Profile")}</div>
                                <div className="col-5 ">
                                    {t("Organizations")}
                                </div>
                                <div className="col-5">
                                    {auth.user.role.code ===
                                        "SU" ? t("Super Admin") : 
                                        // t(auth.user.organization.name)
                                        t(profile.organizationName)
                                    }

                                </div>
                            </div>
                            <div className="row p-3 text-lg">
                                <div className="col-5">
                                    {t("Role")}
                                </div>
                                <div className="col-5">
                                    {auth.user.role.code === "SU" ? t("Super Admin") : t(auth.user.role.name)}
                                </div>
                            </div>
                            <div className="row p-3 text-lg">
                                <div className="col-5">
                                    {t("Email")}
                                </div>
                                <div className="col-5">
                                {auth.user.role.code === "SU" ?
                                        t(auth.user.email)
                                        :
                                        t(profile.email)
                                    }
                                </div>
                                {auth.user.role.code === "SU" ?
                                    ""
                                    :
                                    <div className="col-2">
                                        <a role="button" className="btn btn-primary p-2 text-white" style={{ width:"150px",height:"42px", }} data-toggle="modal" data-target="#modal-changForm">
                                            {t("Change Email")}
                                        </a>
                                    </div>
                                }
                            </div>
                            <div className="row p-3 text-lg">
                                <div className="col-5">
                                    {t("Name")}
                                </div>
                                <div className="col-5">
                                    {auth.user.role.code === "SU" ?
                                        t(auth.user.name)
                                        :
                                        t(profile.name)
                                    }
                                </div>
                                {auth.user.role.code === "SU" ?
                                    ""
                                    :
                                    <div className="col-2">
                                        <a role="button" className="btn btn-primary p-2 text-white" style={{ width:"150px",height:"42px", }} data-toggle="modal" data-target="#modal-changForm2">
                                            {t("Change Name")}
                                        </a>
                                    </div>
                                }
                            </div>
                            <div className="row p-3 text-lg">
                                <div className="col-5">
                                    {t("Username")}
                                </div>
                                <div className="col-5">
                                {auth.user.role.code === "SU" ?
                                        t(auth.user.username)
                                        :
                                        t(profile.username)
                                    }
                                </div>
                                {auth.user.role.code === "SU" ?
                                    ""
                                    :
                                    <div className="col-2">
                                        <a role="button" className="btn btn-primary p-2 text-white" style={{ width:"150px",height:"42px", }} data-toggle="modal" data-target="#modal-changForm3">
                                            {t("Change Username")}
                                        </a>
                                    </div>
                                }
                            </div>
                           
                           
                           
                            {/* MA/OP만 ON/OFF 설정 버튼이 보임 */}
                            {auth.user.role.code === "MA" || auth.user.role.code === "OP" ? 
                            (<div className="row p-3 text-lg">
                                <div className="col-5">
                                    {t("Autosave")}
                                </div>


                                <div className="col-5">
                                    {t(profile.autosave)}
                                    <div className="text-muted" style={{ fontSize: 'x-small' }}>
                                    {profile.autosave === 'Y' ? (
                                        <span>{t("Automatically saves every minute after stopping writing")}</span>
                                    ) : (
                                        <span>{t("Doesn't auto-save even after a certain period of time")}</span>
                                    )}
                                    </div>
                                </div>

                                {/* ON/OFF버튼 */}
                                {/* <div className="col-2">
                                    <input type="checkbox" id="chk1" name="chk1" /><label for="chk1"></label>
                                    <input type="checkbox" id="chk1" name="chk1" defaultChecked={isAutoSaveEnabled} onChange={handleAutoSaveToggle} />
                                    <label htmlFor="chk1"></label>
                                </div> */}
  
                                <div className="col-2">
                                        <a role="button" className="btn btn-primary p-2 text-white" style={{ width:"150px",height:"42px", }} data-toggle="modal" data-target="#modal-changForm4">
                                            {t("Change Autosave")}
                                        </a>
                                </div>
                                
                            </div>) 
                            : null}


                            <div className="row p-3 text-lg">
                                <div className="col text-right p-0">
                                    <a href="#signin" role="button" className="btn btn-primary"  data-toggle="modal" data-target="#modal-form">
                                        {/* <small style={{ whiteSpace: "nowrap" }}>{t("Forgot password")}</small> */}
                                        {t("Forgot password")}
                                    </a>
                                </div>
                                {/* {auth.user.role.code === "SU" ?
                                    ""
                                    :
                                    <button type="button" className="btn btn-primary" onClick={updateUser}>
                                        {t("Change Info")}
                                    </button>
                                } */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ForgotPasswordModal email={auth.user.email} />
            <ProfileModal auth={auth} email={auth.user.email} name={auth.user.name} username={auth.user.username} autosave={auth.user.autosave} loadProfile={loadProfile} />
        </>
    );
};

export default Profile;

