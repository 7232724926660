import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ALERT_LEVEL } from "../../constants";
import { alert } from "../common/Alert";
import $ from "jquery";
import useApi from "../../utils/restUtil";
import { hideSpinner, showSpinner } from "../common/spinner/Spinner";


const ProfileModal = (props) => {
  const api = useApi();
  const { get, put } = useApi();
  const { t } = useTranslation();
  //   const [emailOrUserName, setEmailOrUserName] = useState(props.email?props.email:"");
  const [changeEmail, setChangeEmail] = useState(props.email?props.email:"");
  const [changeName, setChangeName] = useState(props.name?props.name:"");
  const [changeUsername, setChangeUsername] = useState(props.username?props.username:"");
  const [changeAutosave, setChangeAutosave] = useState(props.autosave?props.autosave:"");
  
  // const initialAutosave = localStorage.getItem('changeAutosave') || "N";
  // const [changeAutosave, setChangeAutosave] = useState(initialAutosave);

  // useEffect(()=> {
  //   localStorage.setItem('changeAutosave', changeAutosave);
  // }, [changeAutosave]);


  
    const emailUpdate = () => {
      showSpinner();
        let http = put;
        http(`/users/profile${props.auth.user ? "/email/" + props.auth.user.id: ""}`,{email:changeEmail})
            .then((resp) => {
                if (resp && resp.status === 200) {
                    alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
                    props.loadProfile();
                }
            })
            
            .catch((e) => {
                if (e.response && e.response.status === 409)
                    alert(t("email is already registered!"), ALERT_LEVEL.DANGER);
                else alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
            })
            .finally(() => {
                hideSpinner();
                $("#modal-changForm").modal("hide");
            });
    };

    const nameUpdate = () => {
      showSpinner();
        let http = put;
        http(`/users/profile${props.auth.user ? "/name/" + props.auth.user.id: ""}`,{name:changeName})
            .then((resp) => {
                if (resp && resp.status === 200) {
                    alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
                    props.loadProfile();
                }
            })
            
            .catch((e) => {
                if (e.response && e.response.status === 409)
                    alert(t("name is already registered!"), ALERT_LEVEL.DANGER);
                else alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
            })
            .finally(() => {
                hideSpinner();
                $("#modal-changForm2").modal("hide");
            });
    };

    const usernameUpdate = () => {
      showSpinner();
        let http = put;
        http(`/users/profile${props.auth.user ? "/username/" + props.auth.user.id: ""}`,{username:changeUsername})
            .then((resp) => {
                if (resp && resp.status === 200) {
                    alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
                    props.loadProfile();
                }
            })
            
            .catch((e) => {
                if (e.response && e.response.status === 409)
                    alert(t("username is already registered!"), ALERT_LEVEL.DANGER);
                else alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
            })
            .finally(() => {
                hideSpinner();
                $("#modal-changForm3").modal("hide");
            });
    };


    const autosaveUpdate = () => {
      showSpinner();
        let http = put;
        http(`/users/profile${props.auth.user ? "/autosave/" + props.auth.user.id: ""}`,{autosave:changeAutosave})
            .then((resp) => {
                if (resp && resp.status === 200) {
                    alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
                    props.loadProfile();
                }
            })
            
            .catch((e) => {
                  alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
                  
            })
            .finally(() => {
                hideSpinner();
                $("#modal-changForm4").modal("hide");
            });
    };
     

  const onClose = () => {
    $("#modal-changForm").modal("hide");
    // setEmailOrUserName(props.email?props.email:"");
  };
  const onClose2 = () => {
    $("#modal-changForm2").modal("hide");
    // setEmailOrUserName(props.email?props.email:"");
  };
  const onClose3 = () => {
    $("#modal-changForm3").modal("hide");
    // setEmailOrUserName(props.email?props.email:"");
  };
  const onClose4 = () => {
    $("#modal-changForm4").modal("hide");
    // setEmailOrUserName(props.email?props.email:"");
  };
  
  return (
    <div>
      <div class="row">
        <div class="col-md-4">
          <div
            class="modal fade"
            id="modal-changForm"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-changForm"
            aria-hidden="true"
          >
            <div class="modal-dialog modal- modal-dialog-centered modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h6 class="modal-title" id="modal-title-default">
                    {t("Change Email")}
                  </h6>
                  <button type="button" class="close" onClick={() => onClose()}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body p-0">
                  <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body px-lg-5 py-lg-5">
                      <form>
                        <div class="form-group mb-3">
                          <div class="input-group input-group-merge input-group-alternative">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                {/* <i class="ni ni-email-83"></i> */}
                              </span>
                            </div>
                            <input
                              className="form-control pl-1"
                              // placeholder={t("Email / Username")}
                              type="text"
                              //   value={emailOrUserName}
                              value={changeEmail}
                              onChange={(e) => {
                                setChangeEmail(e.target.value)
                                // setEmailOrUserName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div class="text-center">
                          <button type="button" class="btn btn-primary my-4" onClick={() => {
                          emailUpdate();
                          }
                          }>
                            {t("Change Email")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="modal-changForm2"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-changForm2"
            aria-hidden="true"
          >
            <div class="modal-dialog modal- modal-dialog-centered modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h6 class="modal-title" id="modal-title-default">
                    {t("Change Name")}
                  </h6>
                  <button type="button" class="close" onClick={() => onClose2()}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body p-0">
                  <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body px-lg-5 py-lg-5">
                      <form>
                        <div class="form-group mb-3">
                          <div class="input-group input-group-merge input-group-alternative">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                {/* <i class="ni ni-email-83"></i> */}
                              </span>
                            </div>
                            <input
                              className="form-control pl-1"
                              // placeholder={t("Email / Username")}
                              type="text"
                              //   value={emailOrUserName}
                              value={changeName}
                              onChange={(e) => {
                                setChangeName(e.target.value);
                                // setEmailOrUserName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div class="text-center">
                          <button type="button" class="btn btn-primary my-4" onClick={() => {
                            nameUpdate();
                          }}>
                            {t("Change Name")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="modal-changForm3"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-changForm3"
            aria-hidden="true"
          >
            <div class="modal-dialog modal- modal-dialog-centered modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h6 class="modal-title" id="modal-title-default">
                    {t("Change Username")}
                  </h6>
                  <button type="button" class="close" onClick={() => onClose3()}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body p-0">
                  <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body px-lg-5 py-lg-5">
                      <form>
                        <div class="form-group mb-3">
                          <div class="input-group input-group-merge input-group-alternative">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                {/* <i class="ni ni-email-83"></i> */}
                              </span>
                            </div>
                            <input
                              className="form-control pl-1"
                              placeholder={t("Email / Username")}
                              type="text"
                              //   value={emailOrUserName}
                              value={changeUsername}
                              onChange={(e) => {
                                setChangeUsername(e.target.value);
                                // setEmailOrUserName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div class="text-center">
                          <button type="button" class="btn btn-primary my-4" onClick={() => {
                            usernameUpdate();
                          }}>
                            {t("Change Username")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="modal-changForm4"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-changForm4"
            aria-hidden="true"
          >
            <div class="modal-dialog modal- modal-dialog-centered modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h6 class="modal-title" id="modal-title-default">
                    {t("Change Autosave")}
                  </h6>
                  <button type="button" class="close" onClick={() => onClose4()}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body p-0">
                  <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body px-lg-5 py-lg-5">
                      <form>
                        <div class="form-group mb-3">
                          
                          <input type="checkbox" id="chk1" name="chk1" 
                            checked={changeAutosave === "Y"}
                            onChange={(e) => {
                              const value = e.target.checked ? "Y" : "N";
                              setChangeAutosave(value);
                            }}
                          />
                          
                          <label htmlFor="chk1"></label>
                          
                        </div>
                        <div class="text-center">
                          <button type="button" class="btn btn-primary my-4" onClick={() => {
                            autosaveUpdate();
                          }}>
                            {t("Change Autosave")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          
            

          </div>

        </div>
      </div>
  );
};

export default ProfileModal;
