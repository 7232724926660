import React, { useCallback, useEffect, useState } from "react";

let _ID = 0;
let alert;

const Alert = () => {
  const [alerts, setAlerts] = useState([]);

  alert = (msg, level) => {
    setAlerts([...alerts, { id: _ID++, msg: msg, level: level }]);
  };

  const deleteAlert = useCallback((id) => {
    setAlerts((alerts) => alerts.filter((alert) => alert.id !== id));
  }, []);

  return (
    <div
      className="sticky-top position-fixed"
      style={{
        maxWidth: "30rem",
        // position: "absolute",
        top: "2rem",
        right: "2rem",
        zIndex: "9999",
      }}
    >
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert) => (
          <TimeoutAlert key={alert.id} id={alert.id} msg={alert.msg} deleteAlert={deleteAlert} level={alert.level} />
        ))}
    </div>
  );
};

function TimeoutAlert({ id, msg, level, deleteAlert }) {
  useEffect(() => {
    const timer = setTimeout(() => deleteAlert(id), 3000);
    return () => clearTimeout(timer);
  }, [id, deleteAlert]);
  return (
    <div
      className={`alert alert-dismissible fade show 
      ${level ? "alert-" + level : "alert-default"}`}
      role="alert"
    >
      <span className="alert-text">{msg}</span>
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => deleteAlert(id)}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

export { Alert, alert };
