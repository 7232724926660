import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch, Switch, Redirect } from "react-router-dom";
import Regions from "./regions/Regions";
import Stores from "./stores/Stores";
import { PrivateRoute } from "../common/PrivateRoute";
import { useLocation } from "react-router-dom";
import BusinessCategories from "./businessCategories/BusinessCategories";

const StoreManagement = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const location = useLocation();
  return (
    <div className="col-12 container-fluid">
      <div className="row mb-n-2">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link
              to={`${path}/stores`}
              className={"nav-link " + (location.pathname.includes("stores") ? "active" : "")}
            >
              <span className="nav-link-text">{t("Stores")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`${path}/regions`}
              className={"nav-link " + (location.pathname.includes("regions") ? "active" : "")}
            >
              <span className="nav-link-text">{t("Regions")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`${path}/businessCategories`}
              className={"nav-link " + (location.pathname.includes("businessCategories") ? "active" : "")}
            >
              <span className="nav-link-text">{t("Business Categories")}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className=" col-12">
          <Switch>
            <PrivateRoute path={`${path}/stores`} component={Stores} />
            <PrivateRoute path={`${path}/regions`} component={Regions} />
            <PrivateRoute path={`${path}/businessCategories`} component={BusinessCategories} />
            <PrivateRoute exact path={`${path}/`} component={() => <Redirect to={`${path}/stores`} />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default StoreManagement;
