import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import { AppContext } from "../../../AppContext";

const Store = ({ store, setStore, loadStores, organizations }) => {
  const { t } = useTranslation();
  const { get, post, put } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [regions, setRegions] = useState([]);
  const [businessCategories, setBusinessCategories] = useState([]);

  const saveStore = () => {
    $("#storeModal").modal("hide");
    showSpinner();
    let http = post;
    if (store.id) http = put;
    http(`/stores${store.id ? "/" + store.id : ""}`, store)
      .then((resp) => {
        if (resp && resp.status === 200) {
          alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
          loadStores();
        }
      })
      .finally(() => {
        hideSpinner();
        reset();
      });
  };

  const reset = () => {
    setStore({
      name: "",
      address: "",
      city: "",
      organization: user.role.code === "SU" ? "" : user.organization.id,
      region: "",
      businessCategory: "",
    });
  };

  useEffect(() => {
    showSpinner();
    get("/regions")
      .then((resp) => {
        if (resp && resp.data) {
          setRegions(resp.data);
        }
      })
      .finally(hideSpinner);
    showSpinner();
    get("/businessCategories")
      .then((resp) => {
        if (resp && resp.data) {
          setBusinessCategories(resp.data);
        }
      })
      .finally(hideSpinner);
  }, [get]);

  return (
    <div
      className="modal fade"
      id="storeModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="storeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="storeModalLabel">
              {t(store.id ? t("Edit Store") : t("Add Store"))}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={reset}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <label className="form-control-label">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Name")}
                value={store.name}
                onChange={(e) => {
                  setStore({ ...store, name: e.target.value });
                }}
              />
            </div>
            <div className="mt-4">
              <label className="form-control-label">{t("Address")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Address")}
                value={store.address}
                onChange={(e) => {
                  setStore({ ...store, address: e.target.value });
                }}
              />
            </div>
            <div className="mt-4">
              <label className="form-control-label">{t("City")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("City")}
                value={store.city}
                onChange={(e) => {
                  setStore({ ...store, city: e.target.value });
                }}
              />
            </div>
            {user.role.code === "SU" ? (
              <div className="mt-4">
                <label className="form-control-label">{t("Organization")}</label>
                <select
                  className="form-control"
                  placeholder={t("Organization")}
                  value={store.organization}
                  onChange={(e) => {
                    setStore((store) => {
                      return { ...store, organization: e.target.value };
                    });
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {organizations.map((organization) => {
                    return (
                      <option value={organization.id} key={organization.id}>
                        {organization.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              ""
            )}
            <div className="mt-4">
              <label className="form-control-label">{t("Region")}</label>
              <select
                className="form-control"
                placeholder={t("Region")}
                value={store.region}
                onChange={(e) => {
                  setStore({ ...store, region: e.target.value });
                }}
              >
                <option value="">{t("Select one")}</option>
                {regions.map((region) => {
                  return (
                    <option key={region.id} value={region.id}>
                      {region.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-4">
              <label className="form-control-label">{t("Business Category")}</label>
              <select
                className="form-control"
                placeholder={t("Business Category")}
                value={store.businessCategory}
                onChange={(e) => {
                  setStore({ ...store, businessCategory: e.target.value });
                }}
              >
                <option value="">{t("Select one")}</option>
                {businessCategories.map((businessCategory) => {
                  return (
                    <option key={businessCategory.id} value={businessCategory.id}>
                      {businessCategory.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={reset}>
              {t("Cancel")}
            </button>
            <button type="button" className="btn btn-primary" onClick={saveStore}>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
