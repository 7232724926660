import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../../../utils/restUtil";
import { Types } from "../../../../formManagement/formBuilder/components/Types";
import styles from "./Field.module.css";

const Field = ({ field, report, setReport, sectionIdx, fieldIdx }) => {

  const { t } = useTranslation();

  const imageInputRef = useRef();

  const isReadOnly = useMemo(() => {
    return report.readOnly || report.status !== "draft";
  }, [report]);

  const getField = () => {
    switch (field.type) {
      case Types.TEXT:
        return (
          <input
            type="text"
            className="form-control"
            value={report.items[sectionIdx].items[fieldIdx].answer}
            disabled={isReadOnly}
            onChange={(e) => {
              report.items[sectionIdx].items[fieldIdx].answer = e.target.value;
              setReport({ ...report });
            }}
          />
        );
      case Types.LONGTEXT:
        return (
          <textarea
            type="text"
            className="form-control"
            value={report.items[sectionIdx].items[fieldIdx].answer}
            disabled={isReadOnly}
            onChange={(e) => {
              report.items[sectionIdx].items[fieldIdx].answer = e.target.value;
              setReport({ ...report });
            }}
          />
        );
        case Types.DESCRIPTION:
          return (
            <div style={{textAlign:'left', height:'auto', border:'2px dashed red', padding:'10px 15px', borderRadius:'10px', color:'black'}}>
              {field.text.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
                
            </div>
          );  

      case Types.NUMBER:
        const { startValue, endValue } = field.config;

        // 값 가져오기
        const value = report.items[sectionIdx].items[fieldIdx].answer;
        
        // 범위를 벗어나는지 확인
        const isOutOfRange = (startValue !== undefined && parseFloat(value) < parseFloat(startValue)) ||
                             (endValue !== undefined && parseFloat(value) > parseFloat(endValue));
      
        return (
          <div className={`input-group ${isOutOfRange ? 'has-error' : ''}`}>
            <input
              type="number"
              className={`form-control ${isOutOfRange ? 'is-invalid' : ''}`}
              step="any"
              value={value}
              disabled={isReadOnly}
              onChange={(e) => {
                report.items[sectionIdx].items[fieldIdx].answer = e.target.value;
                setReport({ ...report });
              }}
            />
            {field.config.unit ? (
              <div className="input-group-append">
                <span className="input-group-text">{field.config.unit}</span>
              </div>
            ) : (
              ""
            )}
            {isOutOfRange && <div className="invalid-feedback">{t("Outside normal range.")}</div>}
          </div>
        );

      case Types.DATE:
        return (
          <>
            <input
              type="date"
              className="form-control col-lg-6 col-12"
              value={report.items[sectionIdx].items[fieldIdx].answer}
              disabled={isReadOnly}
              onChange={(e) => {
                report.items[sectionIdx].items[fieldIdx].answer = e.target.value;
                setReport({ ...report });
              }}
            />
          </>
        );
      case Types.TIME:
        return (
          <input
            type="time"
            className="form-control col-lg-6 col-12"
            value={report.items[sectionIdx].items[fieldIdx].answer}
            disabled={isReadOnly}
            onChange={(e) => {
              report.items[sectionIdx].items[fieldIdx].answer = e.target.value;
              setReport({ ...report });
            }}
          />
        );
      case Types.DROPDOWN:
        return (
          <select
            className="form-control"
            value={report.items[sectionIdx].items[fieldIdx].answer}
            disabled={isReadOnly}
            onChange={(e) => {
              report.items[sectionIdx].items[fieldIdx].answer = e.target.value;
              setReport({ ...report });
            }}
          >
            <option value={""}>{t("Select one")}</option>
            {field.config.answerOptions.map((option, idx) => {
              return (
                <option key={idx} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      case Types.RADIO:
        let radioAnswer = report.items[sectionIdx].items[fieldIdx].answer;
        return (
          <>
            {field.config.answerOptions.map((option, idx) => {
              return (
                <div className="custom-control custom-radio" key={idx}>
                  <input
                    type="radio"
                    id={sectionIdx + "00" + fieldIdx + "" + idx}
                    name={sectionIdx + "00" + fieldIdx + "" + idx}
                    className="custom-control-input"
                    checked={radioAnswer === option}
                    disabled={isReadOnly}
                    onChange={(e) => {
                      if (e.target.checked) {
                        report.items[sectionIdx].items[fieldIdx].answer = option;
                        setReport({ ...report });
                      }
                    }}
                  />
                  <label className="custom-control-label" htmlFor={sectionIdx + "00" + fieldIdx + "" + idx}>
                    {option}
                  </label>
                </div>
              );
            })}
          </>
        );
      case Types.CHECKBOX:
        let checkBoxAnswer = report.items[sectionIdx].items[fieldIdx].answer;
        if (!checkBoxAnswer) checkBoxAnswer = [];
        return (
          <>
            {field.config.answerOptions.map((option, idx) => {
              return (
                <div className="custom-control custom-checkbox" key={idx}>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={sectionIdx + "" + fieldIdx + "" + idx}
                    checked={checkBoxAnswer.includes(option)}
                    disabled={isReadOnly}
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (!report.items[sectionIdx].items[fieldIdx].answer)
                          report.items[sectionIdx].items[fieldIdx].answer = [];
                        report.items[sectionIdx].items[fieldIdx].answer.push(option);
                        setReport({ ...report });
                      } else {
                        report.items[sectionIdx].items[fieldIdx].answer.splice(
                          report.items[sectionIdx].items[fieldIdx].answer.indexOf(option),
                          1
                        );
                        setReport({ ...report });
                      }
                    }}
                  />
                  <label className="custom-control-label" htmlFor={sectionIdx + "" + fieldIdx + "" + idx}>
                    {option}
                  </label>
                </div>
              );
            })}
          </>
        );
      case Types.IMAGE:
        return (
          <>
            <div className="custom-file">
              <input
                ref={imageInputRef}
                type="file"
                accept="image/*"
                // 20230614KSW 아래 정보 주석처리하면 IOS도 파일 선택하게 뜸.
                // capture="camera"
                // accept="image/*"
                // accept="image/png, image/jpeg;capture=camera"
                //accept=".jpg, .png"
                disabled={isReadOnly}
                style={{ display: "none" }}
                className="custom-file-input"
                id={sectionIdx + "" + fieldIdx}
                onChange={(e) => {
                  // const fileName = e.target.files[0].name;
                  // const reader = new FileReader();
                  // reader.onload = (e) => {
                  //   report.items[sectionIdx].items[fieldIdx].answer = { name: fileName, blob: e.target.result };
                  //   setReport({ ...report });
                  // };
                  // reader.onerror = (e) => {
                  //   alert(t("Error occurred while choosing image...", ALERT_LEVEL.DANGER));
                  // };
                  // reader.readAsDataURL(e.target.files[0]);
                  // 20220729KSW 사진 날짜 업로드
                  //console.log("#############",    e.target.files[0].name);
                if(e.target.files[0]){
                    report.items[sectionIdx].items[fieldIdx].answer = {
                      name: e.target.files[0].name,
                      file: e.target.files[0],
                      upDate:e.target.files[0].lastModified?new Date(e.target.files[0].lastModified)?.toLocaleString():"",
                      isUploaded: false,
                    }
                 };
                  setReport({ ...report });
                }}
              />
              <label className="custom-file-label" htmlFor={sectionIdx + "" + fieldIdx} style={{ overflow: "hidden" }}>
                {report.items[sectionIdx].items[fieldIdx].answer?.name || t("Choose Image")}
              </label>
            </div>
            {report.items[sectionIdx].items[fieldIdx].answer ? (
              <>
              <div className="mt-2">
                {(() => {
                  if(report.items[sectionIdx].items[fieldIdx].answer.file && Object.keys(report.items[sectionIdx].items[fieldIdx].answer.file).length == 0){
                    // console.log('reort#######',report.items[sectionIdx]);
                  }
                  let url = "";
                  if (report.items[sectionIdx].items[fieldIdx].answer.isUploaded) {
                    url = `${API_URL}/reports/attachments/${report.items[sectionIdx].items[fieldIdx].answer.name}`;
                  }

                     //20220628 파일명 없을때 에러나오는 화면 에러 처림.  
                   //else if (report.items[sectionIdx].items[fieldIdx].answer.file)
                  else if (report.items[sectionIdx].items[fieldIdx].answer.file 
                  && Object.keys(report.items[sectionIdx].items[fieldIdx].answer.file).length > 0) {
                  url = URL.createObjectURL(report.items[sectionIdx].items[fieldIdx].answer.file);
                  }
                  //  console.log("url is@@@@@",url,"")
                  if (url) {
                    return (
                      <img
                        style={{ maxWidth: "90%", maxHeight: "400px", cursor: "pointer" }}
                        alt={"Preview"}
                        src={url}
                        onClick={() => {
                          window.open(url, "_blank");
                        }}
                      />
                    );
                  }
                })()}
                <span
                  className="fa fa-times pl-2 pt-2"
                  style={{ position: "absolute" }}
                  onClick={() => {
                    if (imageInputRef.current) imageInputRef.current.value = null;
                    report.items[sectionIdx].items[fieldIdx].answer = null;
                    setReport({ ...report });
                  }}
                ></span>
              </div>
              {
                // 20220729KSW 사진 날짜 업로드
                 report.items[sectionIdx].items[fieldIdx].answer&& 
                 <div className="text-right">
                 {/* 이미지 파일의 속성 시간을 표시함 */}
                 {report.items[sectionIdx].items[fieldIdx].answer.upDate}
                 {/* 등록 시점의 시간 날짜 */}
                 {/* {new Date().toLocaleString()} */}
                 </div>
               } 
              </>
            ) : (
              ""
            )}
          </>
        );
      default:
        break;
    }
  };
  return (
    <div className={`row mb-2 mt-1 mx-auto pt-2 d-flex justify-content-center ${field.config.bakColor ? styles.ezFormBgcolor : ""}`}>
      {field.type === Types.DESCRIPTION && (
      <div className={`form-group col-12 text-left ${styles["form-group"]}`}>{getField()}</div>)}
      {field.type !== Types.DESCRIPTION && (
      <div className={`field col-sm-12 col-md-4`}>
        <div className={`form-group col-12 ${styles["form-group"]} mb-0  text-sm-left text-md-right`}>
          <label className="form-control-label" >
            {field.text} {field.config.required ? <span id={"ez-required"+sectionIdx+fieldIdx} className="text-red">*</span> : ""}
          </label>
        </div>
      </div>)}
      {field.type !== Types.DESCRIPTION && (
      <div className={`field col-sm-12 col-md-8`}>
        <div className={`form-group col-12 text-left ${styles["form-group"]}`}>{getField()}</div>
      </div>)}
    </div>
  );
};

export default Field;
