import axios from "axios";
import { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";

export const API_URL = process.env.REACT_APP_API_URL;

const useApi = () => {
  const { auth } = useContext(AppContext);

  return useMemo(() => {
    const get = async (path, filter, options) => {
      let url = API_URL + path;
      if (filter && Object.keys(filter) && Object.keys(filter).length > 0) {
        url = url + "?filter=" + JSON.stringify(filter);
      }
      const data = await axios
        .get(
          url,
          auth && auth.token
            ? {
                headers: {
                  "x-access-token": auth.token,
                },
                ...options,
              }
            : ""
        )
        .then((resp) => {
          return resp;
        })
        .catch((e) => {
          console.error(e);
          throw e;
        });
      return data;
    };

    const post = async (path, body = {}) => {
      const data = await axios
        .post(
          API_URL + path,
          body,
          auth && auth.token
            ? {
                headers: {
                  "x-access-token": auth.token,
                },
              }
            : ""
        )
        .then((resp) => {
          return resp;
        })
        .catch((e) => {
          console.error(e);
          throw e;
        });
      return data;
    };

    const put = async (path, body = {}) => {
      const data = await axios
        .put(
          API_URL + path,
          body,
          auth && auth.token
            ? {
                headers: {
                  "x-access-token": auth.token,
                },
              }
            : ""
        )
        .then((resp) => {
          return resp;
        })
        .catch((e) => {
          console.error(e);
          throw e;
        });
      return data;
    };

    const del = async (path) => {
      const data = await axios
        .delete(
          API_URL + path,
          auth && auth.token
            ? {
                headers: {
                  "x-access-token": auth.token,
                },
              }
            : ""
        )
        .then((resp) => {
          return resp;
        })
        .catch((e) => {
          console.error(e);
          throw e;
        });
      return data;
    };

    const getBinary = (path) => {
      return new Promise((resolve, reject) => {
        fetch(API_URL + path, {
          method: "GET",
          headers: {
            "x-access-token": auth.token,
          },
        }).then((resp) => {
          if (resp.status && resp.status === 200) {
            return resolve(resp.blob());
          } else {
            throw new Error(resp.message);
          }
        });
      });
    };

    return { get, post, put, del, getBinary };
  }, [auth]);
};

export default useApi;
