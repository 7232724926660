import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import { AppContext } from "../../../AppContext";

const BusinessCategory = ({ businessCategory, setBusinessCategory, loadBusinessCategories, organizations }) => {
  const { t } = useTranslation();
  const { post, put } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const saveBusinessCategory = () => {
    showSpinner();
    let http = post;
    if (businessCategory.id) http = put;
    http(`/businessCategories${businessCategory.id ? "/" + businessCategory.id : ""}`, businessCategory)
      .then((resp) => {
        if (resp && resp.status === 200) {
          $("#businessCategoryModal").modal("hide");
          alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
          loadBusinessCategories();
        }
      })
      .finally(() => {
        hideSpinner();
        reset();
      });
  };

  const reset = () => {
    setBusinessCategory({ name: "", organization: user.role.code === "SU" ? "" : user.organization.id });
  };

  return (
    <div
      className="modal fade"
      id="businessCategoryModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="businessCategoryModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="businessCategoryModalLabel">
              {t((businessCategory.id ? "Edit" : "Add") + " Business Category")}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={reset}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <label className="form-control-label">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Name")}
                value={businessCategory.name}
                onChange={(e) => {
                  setBusinessCategory((businessCategory) => {
                    return { ...businessCategory, name: e.target.value };
                  });
                }}
              />
            </div>
            {user.role.code === "SU" ? (
              <div className="mt-3">
                <label className="form-control-label">{t("Organization")}</label>
                <select
                  className="form-control"
                  placeholder={t("Organization")}
                  value={businessCategory.organization}
                  onChange={(e) => {
                    setBusinessCategory((businessCategory) => {
                      return { ...businessCategory, organization: e.target.value };
                    });
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {organizations.map((organization) => {
                    return (
                      <option value={organization.id} key={organization.id}>
                        {organization.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={reset}>
              {t("Cancel")}
            </button>
            <button type="button" className="btn btn-primary" onClick={saveBusinessCategory}>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCategory;
