import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../../../utils/restUtil";
import styles from "./Forms.module.css";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { useTranslation } from "react-i18next";
import Confirm from "../../common/Confirm";
import SearchBar from "../../common/search/SearchBar";

const Forms = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { get, del } = useApi();

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getForms = useCallback(() => {
    let filter = {};
    if (history.location.state && history.location.state.filter && history.location.state.filter.status) {
      filter = {
        ...filter,
        status: history.location.state.filter.status,
      };
    }
    if (searchText) {
      filter = {
        ...filter,
        name: { $regex: "^.*" + searchText + ".*", $options: "i" },
      };
    }
    showSpinner();
    get(`/forms`, filter)
      .then((resp) => {
        if (resp && resp.data && resp.data.length > 0) {
          setForms(resp.data);
        } else {
          setForms([]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, history, searchText]);

  const deleteForm = (id) => {
    showSpinner();
    del(`/forms/${id}`)
      .then((resp) => {
        getForms();
        alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  };
// console.log(forms);
  useEffect(() => {
    getForms();
  }, [getForms, history.location.state]);

  return (
    <div>
      <div className="row mb-2">
        <div className={`col mt-3`}>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onClick={() => getForms()}
            onKeyPress={() => getForms()}
          />
        </div>
        <div className="col mt-3">
          <div className={`${styles["actionBtns"]}`}>
            <button
              className="btn btn-icon btn-secondary"
              onClick={(e) => {
                history.push("/formManagement/formBuilder");
                e.stopPropagation();
              }}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
                <span className="btn-inner--text">{t("Form")}</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="table-responsive">
              <div className="">
                <table className="table align-items-center">
                  <thead className="thead-light">
                    <tr>
                      <th>{t("No.")}</th>
                      <th>{t("Name")}</th>
                      <th>{t("Form Category")}</th>
                      <th>{t("Status")}</th>
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {forms.map((form, idx) => {
                      return (
                        <tr className={styles.formRow} onClick={(e) => {}} key={form.id}>
                          <td>{idx + 1}</td>
                          <td>{form.name}</td>
                          <td>{form.formCategory?.name}</td>
                          <td>{t(form.status?.toUpperCase())}</td>
                          <td>
                            <span
                              className="fas fa-lg fa-edit pr-3"
                              title={t("Edit Form")}
                              onClick={(e) => {
                                history.push(`/formManagement/formBuilder`, { form: form });
                                e.stopPropagation();
                              }}
                            ></span>
                            <span
                              className="fas fa-lg fa-copy pr-3"
                              title={t("Copy Form")}
                              onClick={(e) => {
                                const copyForm = JSON.parse(JSON.stringify(form));
                                delete copyForm.id;
                                copyForm.name = `${copyForm.name} (copy)`;
                                history.push(`/formManagement/formBuilder`, { form: copyForm });
                                e.stopPropagation();
                              }}
                            ></span>
                            <span
                              className={`fa fa-lg fa-trash text-danger`}
                              title={t("Delete Form")}
                              data-toggle="modal"
                              data-target="#formDeleteModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={(e) => {
                                setSelectedForm(form);
                              }}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        modalId="formDeleteModal"
        text={
          <>
            {t(`Are you sure you want to delete the form`) + " "}
            <b>{selectedForm.name}</b>
          </>
        }
        onYesClick={() => deleteForm(selectedForm.id)}
        level={ALERT_LEVEL.DANGER}
      />
    </div>
  );
};

export default Forms;
