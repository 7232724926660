import React, { useCallback, useContext, useEffect, useState } from "react";
import useApi from "../../../utils/restUtil";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import styles from "./FormCategories.module.css";
import { useTranslation } from "react-i18next";
import FormCategory from "./FormCategory";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { AppContext } from "../../../AppContext";
import Confirm from "../../common/Confirm";
import SearchBar from "../../common/search/SearchBar";

const FormCategories = () => {
  const { t } = useTranslation();
  const { get, del } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [formCategories, setFormCategories] = useState([]);
  const [selectedFormCategory, setSelectedFormCategory] = useState([]);
  const [searchText, setSearchText] = useState("");

  const loadFormCategories = useCallback(() => {
    showSpinner();
    get("/formCategories", { name: { $regex: "^.*" + searchText + ".*", $options: "i" } })
      .then((resp) => {
        if (resp && resp.data) setFormCategories(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, searchText]);

  const deleteFormCategory = (id) => {
    showSpinner();
    del("/formCategories/" + id)
      .then((resp) => {
        if (resp && resp.data) {
          alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
          loadFormCategories();
        }
      })
      .finally(hideSpinner);
  };

  useEffect(() => {
    loadFormCategories();
  }, [loadFormCategories]);

  return (
    <div>
      <div className="row mb-2">
        <div className={`col mt-3`}>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onClick={() => loadFormCategories()}
            onKeyPress={() => loadFormCategories()}
          />
        </div>
        <div className="col mt-3">
          <div className={`${styles["actionBtns"]}`}>
            <button className="btn btn-icon btn-secondary" data-toggle="modal" data-target="#formCategoryModal">
              <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
                <span className="btn-inner--text">{t("Form Category")}</span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="table-responsive">
              <div className="">
                <table className="table align-items-center">
                  <thead className="thead-light">
                    <tr>
                      <th>{t("No.")}</th>
                      <th>{t("Name")}</th>
                      <th>{t("Business Category")}</th>
                      {user.role.code === "SU" ? <th>{t("Organization")}</th> : ""}
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {formCategories.map((formCategory, idx) => {
                      return (
                        <tr key={formCategory.id}>
                          <td>{idx + 1}</td>
                          <td>{formCategory?.name}</td>
                          <td>{formCategory?.businessCategory?.name}</td>
                          {user.role.code === "SU" ? <td>{formCategory.organization.name}</td> : ""}
                          <td>
                            <span
                              className={`fa fa-lg fa-trash text-danger`}
                              data-toggle="modal"
                              data-target="#formCatDeleteModal"
                              data-backdrop="static"
                              data-keyboard="false"
                              onClick={(e) => {
                                setSelectedFormCategory(formCategory);
                              }}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormCategory loadFormCategories={loadFormCategories} />
      <Confirm
        modalId="formCatDeleteModal"
        text={
          <>
            {t(`Are you sure you want to delete the form category`) + " "}
            <b>{selectedFormCategory.name}</b>
          </>
        }
        onYesClick={() => deleteFormCategory(selectedFormCategory.id)}
        level={ALERT_LEVEL.DANGER}
      />
    </div>
  );
};

export default FormCategories;
