import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import { AppContext } from "../../../AppContext";

const Region = ({ region, setRegion, loadRegions, organizations }) => {
  const { t } = useTranslation();
  const { post, put } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const saveRegion = () => {
    showSpinner();
    let http = post;
    if (region.id) http = put;
    http(`/regions${region.id ? "/" + region.id : ""}`, region)
      .then((resp) => {
        if (resp && resp.status === 200) {
          $("#regionModal").modal("hide");
          alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
          loadRegions();
        }
      })
      .finally(() => {
        hideSpinner();
        reset();
      });
  };

  const reset = () => {
    setRegion({ name: "", organization: user.role.code === "SU" ? "" : user.organization.id });
  };

  return (
    <div
      className="modal fade"
      id="regionModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="regionModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="regionModalLabel">
              {t(region.id ? t("Edit Region") : t("Add Region"))}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={reset}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <label className="form-control-label">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Name")}
                value={region.name}
                onChange={(e) => {
                  setRegion((region) => {
                    return { ...region, name: e.target.value };
                  });
                }}
              />
            </div>
            {user.role.code === "SU" ? (
              <div className="mt-3">
                <label className="form-control-label">{t("Organization")}</label>
                <select
                  className="form-control"
                  placeholder={t("Organization")}
                  value={region.organization}
                  onChange={(e) => {
                    setRegion((region) => {
                      return { ...region, organization: e.target.value };
                    });
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {organizations.map((organization) => {
                    return (
                      <option value={organization.id} key={organization.id}>
                        {organization.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={reset}>
              {t("Cancel")}
            </button>
            <button type="button" className="btn btn-primary" onClick={saveRegion}>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Region;
