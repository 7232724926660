import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { useTranslation } from "react-i18next";
import $ from "jquery";

const Header = () => {
  const { t, i18n } = useTranslation();
  const { setAuth } = useContext(AppContext);

  const history = useHistory();

  return (
    <nav
      className="navbar border-bottom navbar-horizontal navbar-expand navbar-dark bg-primary px-lg-5"
      style={{ position: "sticky", top: "0", zIndex: "999" }}
    >
      <div className="d-flex">
        <h1 className="mb-0">
          <span
            className={"pr-4 d-xl-none d-lg-none d-md-none"}
            onClick={() => {
              $("#sidenav-main").toggleClass("active");
            }}
          >
            <span className="fas fa-bars text-white"></span>
          </span>
          <Link to="/">
            <span className="fas fa-home text-white"></span>
          </Link>
        </h1>
      </div>
      <div className="ml-auto">
        {/* <button
          className="navbar-toggler py-0"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <div>
          {/* <div className="navbar-collapse-header">
            <div className="row">
              <div className="col-6 collapse-brand">Settings</div>
              <div className="col-6 collapse-close">
                <button
                  type="button"
                  className="navbar-toggler py-0"
                  data-toggle="collapse"
                  data-target="#navbar-default"
                  aria-controls="navbar-default"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fas fa-times text-dark"></i>
                </button>
              </div>
            </div>
          </div> */}

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <span className={`nav-link`}>
                <select
                  className={`nav-link p-0 text-darker`}
                  onChange={(e) => {
                    i18n.changeLanguage(e.target.value);
                  }}
                  value={i18n.language || "en"}
                >
                  <option value="ja">日本語</option>
                  <option value="en">{t("English")}</option>
                </select>
              </span>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-link-icon"
                href="#menu"
                id="navbar-default_dropdown_1"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="ni ni-single-02"></i>
                {/* <span className="nav-link-inner--text d-lg-none">{t("Settings")}</span> */}
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar-default_dropdown_1">
                <Link to="/profile" className="dropdown-item">
                  {t("Profile")}
                </Link>
                {/* <Link to="/" className="dropdown-item">
                  {t("Settings")}
                </Link> */}
                <div className="dropdown-divider"></div>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => {
                    setAuth(null);
                    sessionStorage.clear();
                    localStorage.clear();
                    history.push("/signIn");
                  }}
                >
                  {t("Logout")}
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
