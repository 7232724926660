import React, { useContext, useEffect, useState } from "react";
import useApi from "../utils/restUtil";
import { alert } from "./common/Alert";
import { ALERT_LEVEL } from "../constants";
import { hideSpinner, showSpinner } from "./common/spinner/Spinner";
import { AppContext } from "../AppContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ForgotPasswordModal from "./ForgotPasswordModal";

const SignIn = () => {
  const { t } = useTranslation();
  const api = useApi();
  const { auth, setAuth } = useContext(AppContext);

  const history = useHistory();

  const [emailOrUserName, setEmailOrUserName] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const signIn = () => {
    showSpinner();
    api
      .post("/auth/signin", {
        emailOrUserName: emailOrUserName,
        password: password,
      })
      .then((resp) => {
        if (resp && resp.data && resp.data.token) {
          setAuth((auth) => {
            return { ...auth, token: resp.data.token, user: resp.data.user };
          });
          if (remember) localStorage.setItem("auth", JSON.stringify({ token: resp.data.token, user: resp.data.user }));
          history.push("/");
        } else throw new Error("No token in response");
      })
      .catch((e) => {
        if(e.response && e.response.status === 403){
          alert("Account Blocked, Please Contact Admin!", ALERT_LEVEL.DANGER);
        }else{
          alert("Email or password is incorrect", ALERT_LEVEL.DANGER);
        }
        console.error(e);
      })
      .finally(hideSpinner);
  };

  useEffect(() => {
    if (auth && auth.token && auth.user) history.push("/");
  }, [auth, history]);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      setAuth(JSON.parse(localStorage.getItem("auth")));
    }
  }, [setAuth]);

  return (
    <div className="main-content">
      <div className="container mt-8 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div className="card bg-secondary border-0 mb-0">
              <div className="card-body px-lg-5 py-lg-5">
                <h3 className="text-center mb-4">{t("Sign in with credentials")}</h3>
                <form>
                  <div className="form-group mb-3">
                    <div className="input-group input-group-merge input-group-alternative">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="ni ni-email-83"></i>
                        </span>
                      </div>
                      <input
                        className="form-control pl-1"
                        placeholder={t("Email / Username")}
                        type="email"
                        value={emailOrUserName}
                        onChange={(e) => {
                          setEmailOrUserName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group input-group-merge input-group-alternative">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="ni ni-lock-circle-open"></i>
                        </span>
                      </div>
                      <input
                        className="form-control pl-1"
                        placeholder={t("Password")}
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="rememberMeCheckbox"
                        type="checkbox"
                        checked={remember}
                        onChange={(e) => {
                          setRemember(e.target.checked);
                        }}
                      />
                      <label className="custom-control-label" htmlFor="rememberMeCheckbox">
                        <span className="text-muted">{t("Remember me")}</span>
                      </label>
                    </div>
                    <div class="col-6 text-right">
                      <a href="#signin" role="button" className="text" data-toggle="modal" data-target="#modal-form">
                        <small style={{ whiteSpace: "nowrap" }}>{t("Forgot password?")}</small>
                      </a>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="button" className="btn btn-primary mt-4" onClick={signIn}>
                      {t("Sign in")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ForgotPasswordModal />
      </div>
    </div>
  );
};

export default SignIn;
