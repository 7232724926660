import React, { createContext, useEffect, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [auth, setAuth] = useState(sessionStorage.getItem("auth") ? JSON.parse(sessionStorage.getItem("auth")) : {});
  useEffect(() => {
    sessionStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  const [selectedFormType, setSelectedFormType] = useState(
    sessionStorage.getItem("selectedFormType") ? JSON.parse(sessionStorage.getItem("selectedFormType")) : {}
  );
  useEffect(() => {
    sessionStorage.setItem("selectedFormType", JSON.stringify(selectedFormType));
  }, [selectedFormType]);

  const [notifications, setNotifications] = useState([]);

  const [stores, setStores] = useState([]);

  return (
    <AppContext.Provider
      value={{
        auth,
        setAuth,
        selectedFormType,
        setSelectedFormType,
        notifications,
        setNotifications,
        stores,
        setStores,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
