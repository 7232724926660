import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { PrivateRoute } from "../common/PrivateRoute";
import ReportBuilder from "./reportBuilder/ReportBuilder";
import Reports from "./reports/Reports";

const ReportManagement = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const [activePage, setActivePage] = useState("reports");

  return (
    <div className="col container-fluid">
      <div className="mb-n-2">
        <ul className="nav nav-pills">
          <li className="nav-item" onClick={() => setActivePage("reports")}>
            <Link
              to={{ pathname: `${path}/reports`, state: { filter: {} } }}
              className={"nav-link " + (activePage === "reports" ? "active" : "")}
            >
              <span className="nav-link-text">{t("All Reports")}</span>
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActivePage("submittedReports")}>
            <Link
              to={{ pathname: `${path}/reports`, state: { filter: { status: "submitted" } } }}
              className={"nav-link " + (activePage === "submittedReports" ? "active" : "")}
            >
              <span className="nav-link-text">{t("Submitted Reports")}</span>
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActivePage("draftReports")}>
            <Link
              to={{ pathname: `${path}/reports`, state: { filter: { status: "draft" } } }}
              className={"nav-link " + (activePage === "draftReports" ? "active" : "")}
            >
              <span className="nav-link-text">{t("Draft Reports")}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="">
        <Switch>
          <PrivateRoute exact path={`${path}/reports`} component={Reports} />
          <PrivateRoute exact path={`${path}/report`} component={ReportBuilder} />
          <Redirect to={`${path}/reports`} />
        </Switch>
      </div>
    </div>
  );
};

export default ReportManagement;
