export const ALERT_LEVEL = {
  DEFAULT: "default",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  INFO: "info",
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
};

export const NOTIFICATION_TYPE = {
  OPERATOR_SCHEDULE: "operator-schedule",
  APPROVAL_SCHEDULE: "approval-schedule",
};

export const STATUS_OPTIONS = {
  DRAFT: "draft",
  SUBMITTED: "submitted",
  APPROVED: "approved",
  DELETED: "deleted",
  REJECTED: "rejected",
  CREATED: "created",
  DENIED: "denied"
};
