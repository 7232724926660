import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import { AppContext } from "../../../AppContext";
import { object, string } from "yup";

const inviteSchema = object().shape({
  email: string().email("Email is invalid").required("Email is required"),
  role: string().required("Role is required"),
  organization: string().when("isSU", {
    is: false,
    then: string().required("Organization is required"),
  }),
});

const Invite = ({ loadInvites }) => {
  const { t } = useTranslation();
  const { get, post } = useApi();
  const { auth } = useContext(AppContext);
  const {
    auth: { user },
  } = useContext(AppContext);

  const [roles, setRoles] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [stores, setStores] = useState([]);
  const [invite, setInvite] = useState({
    email: "",
    role: "",
    organization: user.role.code === "SU" ? "" : user.organization.id,
    store: "",
    invitedBy: auth.user.id,
    isSU: false,
  });
  const [error, setError] = useState({});

  const saveInvite = () => {
    inviteSchema
      .validate(invite, { abortEarly: false })
      .then(() => {
        showSpinner();
        if (getPopulatedRole(invite.role).code === "SU") {
          delete invite.organization;
          delete invite.store;
        } else if (getPopulatedRole(invite.role).code === "AD") {
          delete invite.store;
        }
        post(`/invites`, invite)
          .then((resp) => {
            if (resp && resp.status === 200) {
              alert(t("User is invited successfully"), ALERT_LEVEL.SUCCESS);
              $("#inviteModal").modal("hide");
              reset();
              loadInvites();
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 409) alert(t("User is already invited!"), ALERT_LEVEL.DANGER);
            else alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
          })
          .finally(() => {
            hideSpinner();
          });
      })
      .catch((validationErrors) => {
        validationErrors.inner.forEach((validationError) => {
          if (!Object.keys(error).includes(validationError.path)) {
            error[validationError.path] = validationError.message;
          }
        });
        setError({ ...error });
        console.log(error);
        alert(t("Error validating data!"), ALERT_LEVEL.DANGER);
      });
  };

  const reset = () => {
    setInvite({
      email: "",
      role: "",
      organization: user.role.code === "SU" ? "" : user.organization.id,
      invitedBy: auth.user.id,
    });
  };

  const getOrganizations = useCallback(() => {
    showSpinner();
    get("/organizations")
      .then((resp) => {
        if (resp && resp.status === 200) {
          setOrganizations(resp.data);
        }
      })
      .catch((err) => {
        alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  }, [get, t]);

  const getRoles = useCallback(() => {
    showSpinner();
    get("/roles")
      .then((resp) => {
        if (resp && resp.status === 200) {
          setRoles(resp.data);
        }
      })
      .catch((err) => {
        alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  }, [get, t]);

  const getStores = useCallback(() => {
    if (!invite.organization) return;
    showSpinner();
    get(`/stores`, { organization: invite.organization })
      .then((resp) => {
        if (resp && resp.data) setStores(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get, invite.organization]);

  const getPopulatedRole = useCallback(
    (roleId) => {
      const role = roles.find((role) => {
        return role.id === roleId;
      });
      return role;
    },
    [roles]
  );

  useEffect(() => {
    getRoles();
    getOrganizations();
  }, [getRoles, getOrganizations]);

  useEffect(() => {
    getStores();
  }, [getStores]);

  return (
    <div
      className="modal fade"
      id="inviteModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="inviteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="inviteModalLabel">
              {t(`${invite.id ? "Edit" : "Add"} Invite`)}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={reset}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <label className="form-control-label">{t("Email")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Email")}
                value={invite.email}
                onChange={(e) => {
                  delete error.email;
                  setInvite({ ...invite, email: e.target.value });
                }}
              />
              <p className="small text-danger m-0" hidden={!error.email}>
                {t(error.email)}
              </p>
            </div>
            <div className="mt-3">
              <label className="form-control-label">{t("Role")}</label>
              <select
                className="form-control"
                placeholder={t("Role")}
                value={invite.role}
                onChange={(e) => {
                  delete error.role;
                  setInvite((invite) => {
                    return { ...invite, role: e.target.value, isSU: getPopulatedRole(e.target.value)?.code === "SU" };
                  });
                }}
              >
                <option value={t("")}>{t("Select one")}</option>
                {roles.map((role) => {
                  return (
                    <option value={role.id} key={role.id} hidden={role.code === "SU" && user.role.code !== "SU"}>
                      {role.name}
                    </option>
                  );
                })}
              </select>
              <p className="small text-danger m-0" hidden={!error.role}>
                {t(error.role)}
              </p>
            </div>
            {!invite.isSU && user.role.code === "SU" ? (
              <div className="mt-3">
                <label className="form-control-label">{t("Organization")}</label>
                <select
                  className="form-control"
                  placeholder={t("Organization")}
                  value={invite.organization}
                  onChange={(e) => {
                    delete error.organization;
                    setInvite((invite) => {
                      return { ...invite, organization: e.target.value };
                    });
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {organizations.map((organization) => {
                    return (
                      <option value={organization._id} key={organization._id}>
                        {organization.name}
                      </option>
                    );
                  })}
                </select>
                <p className="small text-danger m-0" hidden={!error.organization}>
                  {t(error.organization)}
                </p>
              </div>
            ) : (
              ""
            )}
            {getPopulatedRole(invite.role) &&
            (getPopulatedRole(invite.role).code === "OP" || getPopulatedRole(invite.role).code === "MA") ? (
              <div className="mt-3">
                <label className="form-control-label">{t("Store")}</label>
                <select
                  className="form-control"
                  placeholder={t("Store")}
                  value={invite.store}
                  onChange={(e) => {
                    delete error.store;
                    setInvite((invite) => {
                      return { ...invite, store: e.target.value };
                    });
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {stores.map((store) => {
                    return (
                      <option value={store.id} key={store.id}>
                        {store.name}
                      </option>
                    );
                  })}
                </select>
                <p className="small text-danger m-0" hidden={!error.store}>
                  {t(error.store)}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={reset}>
              {t("Cancel")}
            </button>
            <button type="button" className="btn btn-primary" onClick={saveInvite}>
              {t("Invite")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
