import React, { useState } from "react";
import { Types } from "../../components/Types";
import styles from "./Field.module.css";
import Settings from "./Settings";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

const Field = ({ field, form, setForm, addField, fieldIdx, sectionIdx }) => {
  const { t } = useTranslation();
  const [isOver, setIsOver] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const getField = () => {
    switch (field.type) {
      case Types.TEXT:
        return <input type="text" className="form-control" placeholder={t("Text")} />;
      case Types.LONGTEXT:
        return <textarea type="text" className="form-control" placeholder={t("Longtext")}/>;
      case Types.DESCRIPTION:
        return <textarea type="text" className="form-control" placeholder={t("Description")} style={{width:'100%',height:'200px'}}
        value={field.text}
                  onChange={(e) => {
                    field.text = e.target.value;
                    setForm({ ...form });
                  }}/>;  
      case Types.NUMBER:
        return (
          <div className="input-group">
            <input type="number" className="form-control" placeholder={t("Number")} />
            {field.config.unit ? (
              <div className="input-group-append">
                <span className="input-group-text">{field.config.unit}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      case Types.DATE:
        return <input type="date" className="form-control" placeholder={t("Date")} />;
      case Types.TIME:
        return <input type="time" className="form-control" placeholder={t("Time")} />;
      case Types.DROPDOWN:
        return (
          <select className="form-control">
            <option value="">{t("Select one")}</option>
            {field.config.answerOptions.map((option, idx) => {
              return (
                <option key={idx} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      case Types.RADIO:
        return (
          <>
            {field.config.answerOptions.map((option, idx) => {
              return (
                <div className="custom-control custom-radio" key={idx}>
                  <input
                    type="radio"
                    id={sectionIdx + "" + fieldIdx + "" + idx}
                    name={sectionIdx + "" + fieldIdx + "" + idx}
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor={sectionIdx + "" + fieldIdx + "" + idx}>
                    {option}
                  </label>
                </div>
              );
            })}
          </>
        );
      case Types.CHECKBOX:
        return (
          <>
            {field.config.answerOptions.map((option, idx) => {
              return (
                <div className="custom-control custom-checkbox" key={idx}>
                  <input type="checkbox" className="custom-control-input" id={sectionIdx + "" + fieldIdx + "" + idx} />
                  <label className="custom-control-label" htmlFor={sectionIdx + "" + fieldIdx + "" + idx}>
                    {option}
                  </label>
                </div>
              );
            })}
          </>
        );
      case Types.IMAGE:
        return (
          <div className="custom-file">
            <input
              type="file"
              accept=".jpg, .png, .jpeg"
              style={{ display: "none" }}
              className="custom-file-input"
              id={sectionIdx + "" + fieldIdx}
            />
            <label className="custom-file-label" htmlFor={sectionIdx + "" + fieldIdx}>
              {t("Upload")}
            </label>
          </div>
        );
      default:
        break;
    }
  };

  const sortField = (dragFieldIdx, dragSectionIdx) => {
    const draggedField = form.items[dragSectionIdx].items[dragFieldIdx];
    form.items[dragSectionIdx].items.splice(dragFieldIdx, 1);
    form.items[sectionIdx].items.splice(fieldIdx, 0, draggedField);
    setForm({ ...form });
  };

  return (
    // 20220729KSW-배경색추가
    <div style={field.type === Types.DESCRIPTION ? {display: 'block'} : {}} className={`row mb-2 mt-1 pt-2 ${field.config.bakColor ? styles.ezFormBgcolor : ""}`}>
      <div
        style={{maxWidth:'100%'}}
        draggable={true}
        className={`field col-6 droppable ${isOver ? styles.dropOver : ""} draggable ${
          isDragging ? styles["dragging"] : ""
        }`}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOver(true);
        }}
        onDragLeave={(e) => {
          setIsOver(false);
        }}
        onDrop={(e) => {
          e.stopPropagation();
          if (e.dataTransfer.types.includes("add")) {
            addField(e.dataTransfer.getData("add"), fieldIdx);
          } else if (e.dataTransfer.types.includes("sort") && e.dataTransfer.getData("sort") !== field.id) {
            sortField(e.dataTransfer.getData("dragFieldIdx"), e.dataTransfer.getData("dragSectionIdx"));
          }
          setIsOver(false);
        }}
        onDragStart={(e) => {
          e.dataTransfer.setData("sort", field.id);
          e.dataTransfer.setData("dragFieldIdx", fieldIdx);
          e.dataTransfer.setData("dragSectionIdx", sectionIdx);
          setIsDragging(true);
        }}
        onDragEnd={(e) => {
          setIsDragging(false);
        }}
      >
        <div className={`form-group col-12 ${styles["form-group"]}`}>
          {/* <label className="form-control-label">{field.text}{field.config.required?<span class="text-red">*</span>:""}</label> */}
          <label className="form-control-label">
            {field.type === Types.DESCRIPTION ? "" : field.text}
            {field.config.required && <span className="text-red">*</span>}
          </label>
          <span className={`pr-2 ${styles.actionIcons}`}>
            <span
             style={{position:'relative',zIndex:'1000'}}
              className="fas fa-trash text-danger mr-3"
              onClick={() => {
                form.items[sectionIdx].items.splice(fieldIdx, 1);
                setForm({ ...form });
              }}
            ></span>
            {/* 컴포넌트 설정 버튼   */}
            {field.type !== Types.DESCRIPTION &&(
            <span  style={{position:'relative',zIndex:'1000'}}
            className="fas fa-cog" onClick={() => setShowSettings(!showSettings)}></span>)}
            <div className="">
              {showSettings ? (
                <OutsideClickHandler onOutsideClick={() => setShowSettings(false)}>
                  <Settings field={field} form={form} setForm={setForm} />
                </OutsideClickHandler>
              ) : (
                ""
              )}
            </div>
          </span>
          {getField()}
        </div>
      </div>
      {/* 셀 값 입력 폼 */}
      {field.type !== Types.DESCRIPTION && (
      <div className="col-6">
        <div className="">
          <label className="form-control-label">{t("Excel reference")}</label>
          <input
            type="text"
            className="form-control col-4"
            style={{ textTransform: "uppercase" }}
            placeholder="A1"
            value={field.cellRef}
            onChange={(e) => {
              field.cellRef = e.target.value;
              setForm({ ...form });
            }}
          />
        </div>
      </div>
       )}
    </div>
  );
};

export default Field;
