import React, { useContext, useEffect, useState } from "react";
import useApi from "../../../utils/restUtil";
import Components from "./components/Components";
import Sections from "./sections/Sections";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import { useHistory } from "react-router-dom";
import { ALERT_LEVEL } from "../../../constants";
import { alert } from "../../common/Alert";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../AppContext";

const FormBuilder = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { post, put, del } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [form, setForm] = useState({
    name: "",
    status: "draft",
    formCategory: "",
    xlTemplate: { name: "" },
    items: [],
    organization: user.role.code === "SU" ? "" : user.organization.id,
  });

  const uploadTemplateFile = () => {
    const formData = new FormData();
    formData.append("organization", form.organization);
    formData.append("filename", form.name);
    formData.append("file", form.xlTemplate.file);
    showSpinner();
    post(`/forms/${form.id}/template`, formData)
      .then((resp) => {})
      .catch((e) => {
        alert("Some error has occurred", ALERT_LEVEL.DANGER);
      })
      .finally(hideSpinner);
  };

  const save = () => {
    let http = post;
    if (form.id) http = put;
    showSpinner();
    http(`/forms${form.id ? `/${form.id}` : ""}`, { ...form, xlTemplate: { name: form.xlTemplate.name } })
      .then((resp) => {
        if (resp && resp.status === 200) {
          if (!form.id) setForm({ ...form, id: resp.data.id });
          if (form.xlTemplate.file) uploadTemplateFile();
          alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
        } else alert("Some error has occurred", ALERT_LEVEL.DANGER);
      })
      .catch((e) => {
        alert("Some error has occurred", ALERT_LEVEL.DANGER);
      })
      .finally(hideSpinner);
  };

  const deleteForm = () => {
    if (form.id) {
      showSpinner();
      del(`/forms/${form.id}`)
        .then((resp) => {
          alert(t("Deleted successfully"), ALERT_LEVEL.SUCCESS);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(hideSpinner);
    }
    setForm({
      name: "",
      status: "draft",
      formCategory: "",
      items: [],
      organization: user.role.code === "SU" ? "" : user.organization.id,
    });
  };

  useEffect(() => {
    if (history.location.state && history.location.state.form) {
      const historyStateForm = history.location.state.form;
      setForm({
        ...historyStateForm,
        formCategory: historyStateForm.formCategory.id,
        organization: historyStateForm.organization.id,
      });
    }
  }, [history]);

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row mb-2">
            <div className="col-9"></div>
            <div className="col-3 mb-2">
              <div className={`d-flex justify-content-center`}>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    form.status = "draft";
                    setForm({ ...form });
                    save();
                  }}
                >
                  {t("Save")}
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    form.status = "published";
                    setForm({ ...form });
                    save();
                  }}
                >
                  {t("Publish")}
                </button>
                <button
                  className="btn btn-danger"
                  hidden={true}
                  onClick={() => {
                    deleteForm();
                  }}
                >
                  {form.id ? t("Delete") : t("Reset")}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-9">
              <Sections form={form} setForm={setForm} />
            </div>
            <div className="col-3 drag">
              <Components />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBuilder;
