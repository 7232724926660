import React, { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { Alert } from "./components/common/Alert";
import SignIn from "./components/SignIn";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Spinner from "./components/common/spinner/Spinner";
import { PrivateRoute, PublicRoute } from "./components/common/PrivateRoute";
import Header from "./components/common/Header";
import { AppContext } from "./AppContext";
import { Scrollbars } from "react-custom-scrollbars";
import Sidebar from "./components/common/Sidebar";
import Dashboard from "./components/dashboard/Dashboard";
import ReportManagement from "./components/reportManagement/ReportManagement";
import FormManagement from "./components/formManagement/FormManagement";
import StoreManagement from "./components/storeManagement/StoreManagement";
import OrgManagement from "./components/orgManagement/OrgManagement";
import SignUp from "./components/SignUp";
import Profile from "./components/profile/Profile";
import ResetPassword from "./components/ResetPassword";

function App() {
  const { auth } = useContext(AppContext);
  return (
    <Scrollbars
      style={{ width: "100vw", height: "100vh" }}
      renderThumbVertical={(props) => <div {...props} className="scroll-thumb-vertical black" />}
      renderThumbHorizontal={(props) => <div {...props} className="scroll-thumb-horizontal black" />}
      autoHide
    >
      {auth && auth.token && auth.user && <Sidebar />}
      <div className="main-content">
        {auth && auth.token && auth.user && <Header />}
        <div className="container-fluid mt-5">
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/orgManagement" component={OrgManagement} />
            <PrivateRoute path="/storeManagement" component={StoreManagement} />
            <PrivateRoute path="/reportManagement" component={ReportManagement} />
            <PrivateRoute path="/formManagement" component={FormManagement} />
            {/* 20221026KSW */}
            <PrivateRoute path="/profile" component={Profile} />
            <PublicRoute exact path="/resetPassword/:token" component={ResetPassword} />
            <PublicRoute exact path="/signUp/:token" component={SignUp} />
            <PublicRoute exact path="/signIn" component={SignIn} />
            <PublicRoute exact path="/privacyPolicy" component={PrivacyPolicy} />
            <Redirect to="/dashboard" />
          </Switch>
        </div>
      </div>
      <Alert />
      <Spinner />
    </Scrollbars>
  );
}

export default App;
