import React, { useState } from "react";
import Field from "../field/Field";
import styles from "./Section.module.css";
import { useTranslation } from "react-i18next";

const Section = ({ section, report, setReport, sectionIdx,saveReport }) => {
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <>
      <div className={`card p-1 ${styles.card}`}>
        <div className={`card-header d-flex ${styles["card-header"]}`}>
          <h3
            data-toggle="collapse"
            href={"#collapse" + sectionIdx}
            role="button"
            aria-expanded="true"
            aria-controls={"collapse" + sectionIdx}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCollapsed(!isCollapsed);
            }}
          >
            <span>{section.text}</span>
           
          </h3>
     
          <span className="flex-fill"></span>
          {section.repetable ? (
              <div className={"d-flex justify-content-center"}>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    const itemsToRepeat = [
                      ...section.items.filter((item) => {
                        return !("repeatedIdx" in item);
                      }),
                    ];
                    report.items[sectionIdx].items.push(
                      ...itemsToRepeat.map((item, index) => {
                        return {
                          ...item,
                          answer: "",
                          repeatedIdx: index,
                          repeatedLength: itemsToRepeat.length,
                          cellRef: item.cellRef.replace(/(\d+)$/, function (match, n) {
                            // console.log(report.items[sectionIdx].items.length); // HASH ROUTE
                            return parseInt(n) + report.items[sectionIdx].items.length / itemsToRepeat.length;
                          }),
                        };
                      })
                    );
                    setReport({ ...report });
                  }}
                >
                  {t("Add another")}
                </button>
              </div>
            ) : (
              ""
            )}
        
        </div>

        <div className="collapse show" id={"collapse" + sectionIdx}>
          <div className={`col-12 card-body ${styles["card-body"]} px-0 px-md-5`}>
            {section.items.map((field, idx) => {
              return (
                <div key={idx} id={'section_'+idx}>
                  {field.repeatedIdx === 0 ? (
                    <div className="d-flex flex-row-reverse">
                      <span
                        className="fas fa-trash text-danger pb-3 pt-3 pl-3"
                        onClick={() => {
                          report.items[sectionIdx].items.splice(idx, field.repeatedLength);
                          setReport({ ...report });
                        }}
                      ></span>
                      <div className={"d-flex justify-content-center"}>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            const itemsToRepeat = [
                              ...section.items.filter((item) => {
                                return !("repeatedIdx" in item);
                              }),
                            ];
                            report.items[sectionIdx].items.push(
                              ...itemsToRepeat.map((item, index) => {
                                return {
                                  ...item,
                                  answer: "",
                                  repeatedIdx: index,
                                  repeatedLength: itemsToRepeat.length,
                                  cellRef: item.cellRef.replace(/(\d+)$/, function (match, n) {
                                    // console.log(report.items[sectionIdx].items.length); // HASH ROUTE
                                    return parseInt(n) + report.items[sectionIdx].items.length / itemsToRepeat.length;
                                  }),
                                };
                              })
                            );
                            setReport({ ...report });
                          }}
                        >
                          {t("Add another")}
                        </button>
                      </div>
                      
                    </div>
                  ) : (
                    ""
                  )}
                  <Field field={field} report={report} setReport={setReport} sectionIdx={sectionIdx} fieldIdx={idx} />
                </div>
              );
            })}
          </div>
          {
              report.status === "draft" && !report.readOnly &&
              <div>
                         <button
                            className="btn btn-secondary m-2 float-right mb-4"
                            onClick={() => {
                              report.status = "draft";
                              setReport({ ...report });
                              saveReport();
                            }}
                          >
                            {t("Save")}
                          </button>
              </div>
            }
        </div>
      </div>
    </>
  );
};

export default Section;
