import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ALERT_LEVEL } from "../constants";
import { alert } from "./common/Alert";
import $ from "jquery";
import useApi from "../utils/restUtil";
import { hideSpinner, showSpinner } from "./common/spinner/Spinner";

const ForgotPasswordModal = (props) => {
  const api = useApi();
  const { t } = useTranslation();
  const [emailOrUserName, setEmailOrUserName] = useState(props.email?props.email:"");

  // console.log(props)
  const reset = () => {
    showSpinner();
    api
      .post("/auth/forgotpassword", {
        emailId: emailOrUserName,
      })
      .then((resp) => {
        if (resp && resp.data && resp.data.status === "Success") {
          alert(t("Link sent sucessfully"), ALERT_LEVEL.SUCCESS);
          onClose();
        } else {
          alert(t(resp.data.message), ALERT_LEVEL.DANGER);
        }
      })
      .catch((e) => {
        alert(t(e), ALERT_LEVEL.DANGER);
        console.error(e);
      })
      .finally(hideSpinner);
  };

  const onClose = () => {
    $("#modal-form").modal("hide");
    setEmailOrUserName(props.email?props.email:"");
  };

  return (
    <div>
      <div class="row">
        <div class="col-md-4">
          <div
            class="modal fade"
            id="modal-form"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-form"
            aria-hidden="true"
          >
            <div class="modal-dialog modal- modal-dialog-centered modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h6 class="modal-title" id="modal-title-default">
                    {t("Forgot password")}
                  </h6>
                  <button type="button" class="close" onClick={() => onClose()}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body p-0">
                  <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body px-lg-5 py-lg-5">
                      <form>
                        <div class="form-group mb-3">
                          <div class="input-group input-group-merge input-group-alternative">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="ni ni-email-83"></i>
                              </span>
                            </div>
                            <input
                              className="form-control pl-1"
                              placeholder={t("Email / Username")}
                              type="email"
                              value={emailOrUserName}
                              onChange={(e) => {
                                setEmailOrUserName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div class="text-center">
                          <button type="button" class="btn btn-primary my-4" onClick={() => reset()}>
                            {t("Reset Password")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
