import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import useApi from "../../utils/restUtil";
import { hideSpinner, showSpinner } from "../common/spinner/Spinner";
import ReportBuilder from "../reportManagement/reportBuilder/ReportBuilder";

const Dashboard = () => {
  const { t } = useTranslation();
  const { get } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [totalFormsCount, setTotalFormsCount] = useState(0);
  const [draftFormsCount, setDraftFormsCount] = useState(0);
  const [totalReportsCount, setTotalReportsCount] = useState(0);
  const [draftReportsCount, setDraftReportsCount] = useState(0);

  const getFormsCount = useCallback(
    (filter) => {
      showSpinner();
      return get(`/forms/count`, filter)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            return resp.data.count;
          } else {
            return 0;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(hideSpinner);
    },
    [get]
  );

  const getReportsCount = useCallback(
    (filter) => {
      showSpinner();
      return get(`/reports/count`, filter)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            return resp.data.count;
          } else {
            return 0;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(hideSpinner);
    },
    [get]
  );

  useEffect(() => {
    getFormsCount({}).then((count) => {
      setTotalFormsCount(count);
    });
    getFormsCount({ status: "draft" }).then((count) => {
      setDraftFormsCount(count);
    });
    getReportsCount(
      user.role.code==="OP"?
      {createdBy: user.id }:
      ""
      ).then((count) => {
        setTotalReportsCount(count);
      });
      // getReportsCount({}).then((count) => {
      //   setTotalReportsCount(count);
      // });
  getReportsCount(
    user.role.code==="OP"?
    { status: "draft" ,createdBy: user.id }:
    { status: "draft"}
    ).then((count) => {
      setDraftReportsCount(count);
    });
    // getReportsCount({ status: "draft" ,createdBy: user.id }).then((count) => {
    //   setDraftReportsCount(count);
    // });
  }, [getFormsCount, getReportsCount, user.id, user.role.code]);
// console.log("useruseruseruser###",user)
  return (
    <div className="col container-fluid">
      <div className="card">
        <div className={`card-body`}>
          <div className="row">
            {user.role.code !== "OP" ? (
              <>
                <div className="col-xl-4 col-md-6">
                  <div className="card card-stats">
                    <Link to="/formManagement/forms">
                      <div className="card-body cursor-pointer">
                        <div className="row p-3 text-xl">
                          <div className="col">
                            <h3 className="card-title text-uppercase text-muted mb-0">{t("Total Forms")}</h3>
                            <span className="h2 font-weight-bold mb-0">{totalFormsCount}</span>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i className="fa fa-lg fa-file-alt"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card card-stats">
                    <Link to="/formManagement/forms">
                      <div className="card-body cursor-pointer">
                        <div className="row p-3 text-xl">
                          <div className="col">
                            <h3 className="card-title text-uppercase text-muted mb-0">{t("Draft Forms")}</h3>
                            <span className="h2 font-weight-bold mb-0">{draftFormsCount}</span>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i className="fa fa-lg fa-file-alt"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="col-xl-4 col-md-6">
              <div className="card card-stats">
                <Link to="/reportManagement/reports">
                  <div className="card-body cursor-pointer">
                    <div className="row p-3 text-xl">
                      <div className="col">
                        <h3 className="card-title text-uppercase text-muted mb-0">{t("Total Reports")}</h3>
                        <span className="h2 font-weight-bold mb-0">{totalReportsCount}</span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="fa fa-lg fa-table"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="card card-stats">
                <Link to="/reportManagement/reports">
                  <div className="card-body cursor-pointer">
                    <div className="row p-3 text-xl">
                      <div className="col">
                        <h3 className="card-title text-uppercase text-muted mb-0">{t("Draft Reports")}</h3>
                        <span className="h2 font-weight-bold mb-0">{draftReportsCount}</span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="fa fa-lg fa-table"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {user.role.code === "OP" ? (
              <>
                <div className="">
                  <ReportBuilder/>
                </div>
              </>
            ) : (
              ""
            )}

    </div>
  );
};

export default Dashboard;
