import React, { useState, useEffect, useCallback, useContext } from "react";
import useApi from "../../../../../utils/restUtil";
import styles from "./Section.module.css";
import { hideSpinner, showSpinner } from "../../../../common/spinner/Spinner";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../../AppContext";
import { ALERT_LEVEL } from "../../../../../constants";
import { alert } from "../../../../common/Alert";
import { saveAs } from "file-saver";

const InfoSection = ({ form, setForm }) => {
  const { t } = useTranslation();
  const { get } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [formCategories, setFormCategories] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isCollapsed, setCollapsed] = useState(false);

  const loadFormCategories = useCallback(() => {
    showSpinner();
    get("/formCategories")
      .then((resp) => {
        if (resp && resp.data) setFormCategories(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(hideSpinner);
  }, [get]);

  const loadOrganizations = useCallback(() => {
    showSpinner();
    get("/organizations")
      .then((resp) => {
        if (resp && resp.status === 200) {
          setOrganizations(resp.data);
        }
      })
      .catch((err) => {
        alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  }, [get, t]);

  const downloadFile = () => {
    showSpinner();
    get(`/forms/${form.organization}/template/${form.name}`, {}, { responseType: "blob" })
      .then((resp) => {
        saveAs(resp.data, `${form.xlTemplate.name}`);
      })
      .catch((err) => {
        alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  };

  useEffect(() => {
    loadFormCategories();
    if (user.role.code === "SU") {
      loadOrganizations();
    }
  }, [loadFormCategories, loadOrganizations, user]);

  return (
    <>
      <div className={`card p-1 ${styles.card}`}>
        <div className={`card-header d-flex ${styles["card-header"]}`}>
          <h3 className="flex-fill">{t("Form Details")}</h3>
          <span className={styles.actionBtns}>
            <i
              data-toggle="collapse"
              href="#collapseInfo"
              role="button"
              aria-expanded="true"
              aria-controls="collapseInfo"
              className={`fas ${isCollapsed ? "fa-chevron-up" : "fa-chevron-down"}`}
              onClick={() => {
                setCollapsed(!isCollapsed);
              }}
            ></i>
          </span>
        </div>
        <div className="collapse show" id="collapseInfo">
          <div className={`col-12 card-body ${styles["card-body"]}`}>
            <div className="row">
              <div className="form-group col-6">
                <label className="form-control-label">{t("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Form Name")}
                  value={form.name}
                  onChange={(e) => {
                    setForm((form) => {
                      return { ...form, name: e.target.value };
                    });
                  }}
                />
              </div>
              <div className="form-group col-6">
                <label className="form-control-label">{t("Status")}</label>
                <div>
                  <label
                    className={`form-control-label ${form.id ? (form.published_at ? "success" : "warning") : "danger"}`}
                  >
                    {form.id ? t(form.status.toUpperCase()) : t("Initial Unsaved")}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-6">
                <label className="form-control-label">{t("Form Category")}</label>
                <select
                  className="form-control"
                  value={form.formCategory || ""}
                  onChange={(e) => {
                    setForm((form) => {
                      return { ...form, formCategory: e.target.value };
                    });
                  }}
                >
                  <option value="">{t("Select one")}</option>
                  {formCategories.map((formCategory, idx) => {
                    return (
                      <option value={formCategory.id} key={idx}>
                        {formCategory.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group col-5">
                <label className="form-control-label">{t("Excel Template")}</label>
                <div className="custom-file">
                  <input
                    accept=".xlxs, .xlsx"
                    onChange={(e) => {
                      setForm((form) => {
                        return { ...form, xlTemplate: { name: e.target.files[0].name, file: e.target.files[0] } };
                      });
                    }}
                    type="file"
                    style={{ display: "none" }}
                    className="custom-file-input"
                    id="xlfile"
                    lang="ja"
                  />
                  <label className="custom-file-label text-truncate" htmlFor="xlfile">
                    {form.xlTemplate?.name ? form.xlTemplate.name : t("Upload a template")}
                  </label>
                </div>
              </div>
              <div className=" col-1 align-self-center p-0" onClick={() => downloadFile()}>
                <button type="button" className="small btn btn-primary">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            {user.role.code === "SU" ? (
              <div className="row">
                <div className="col-6">
                  <label className="form-control-label">{t("Organization")}</label>
                  <select
                    className="form-control"
                    placeholder={t("Organization")}
                    value={form.organization}
                    onChange={(e) => {
                      setForm((form) => {
                        return { ...form, organization: e.target.value };
                      });
                    }}
                  >
                    <option value={t("")}>{t("Select one")}</option>
                    {organizations.map((organization) => {
                      return (
                        <option value={organization.id} key={organization.id}>
                          {organization.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoSection;
