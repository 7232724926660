import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../../AppContext";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useContext(AppContext);

  let isAuthenticated = false;
  if (auth && auth.token && auth.user) {
    isAuthenticated = true;
  }

  return (
    <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/signIn" />)} />
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
