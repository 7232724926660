import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import useApi from "../../../utils/restUtil";
import { alert } from "../../common/Alert";
import { ALERT_LEVEL } from "../../../constants";
import { hideSpinner, showSpinner } from "../../common/spinner/Spinner";
import { AppContext } from "../../../AppContext";

const FormCategory = ({ loadFormCategories }) => {
  const { t } = useTranslation();
  const { get, post } = useApi();
  const {
    auth: { user },
  } = useContext(AppContext);

  const [organizations, setOrganizations] = useState([]);
  const [busninessCategories, setBusninessCategories] = useState([]);
  const [formCategory, setFormCategory] = useState({
    name: "",
    businessCategory: "",
    organization: user.role.code === "SU" ? "" : user.organization.id,
  });

  const saveFormCategory = () => {
    showSpinner();
    post("/formCategories", formCategory)
      .then((resp) => {
        if (resp && resp.status === 200) {
          $("#formCategoryModal").modal("hide");
          alert(t("Saved successfully"), ALERT_LEVEL.SUCCESS);
          loadFormCategories();
        }
      })
      .finally(() => {
        hideSpinner();
        reset();
      });
  };

  const reset = () => {
    setFormCategory({
      name: "",
      businessCategory: "",
      organization: user.role.code === "SU" ? "" : user.organization.id,
    });
  };

  const getOrganizations = useCallback(() => {
    showSpinner();
    get("/organizations")
      .then((resp) => {
        if (resp && resp.status === 200) {
          setOrganizations(resp.data);
        }
      })
      .catch((err) => {
        alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  }, [get, t]);

  const getBusinessCategories = useCallback(() => {
    showSpinner();
    get("/businessCategories")
      .then((resp) => {
        if (resp && resp.status === 200) {
          setBusninessCategories(resp.data);
        }
      })
      .catch((err) => {
        alert(t("Some error has occurred!"), ALERT_LEVEL.DANGER);
      })
      .finally(() => {
        hideSpinner();
      });
  }, [get, t]);

  useEffect(() => {
    if (user.role.code === "SU") {
      getOrganizations();
    }
    getBusinessCategories();
  }, [getOrganizations, user, getBusinessCategories]);

  return (
    <div
      className="modal fade"
      id="formCategoryModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="formCategoryModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="formCategoryModalLabel">
              {t("Add Form Category")}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <label className="form-control-label">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Name")}
                value={formCategory.name}
                onChange={(e) => {
                  setFormCategory((formCategory) => {
                    return { ...formCategory, name: e.target.value };
                  });
                }}
              />
            </div>
            <div className="mt-3">
              <label className="form-control-label">{t("Business Category")}</label>
              <select
                className="form-control"
                placeholder={t("Business Category")}
                value={formCategory.businessCategory}
                onChange={(e) => {
                  setFormCategory((formCategory) => {
                    return { ...formCategory, businessCategory: e.target.value };
                  });
                }}
              >
                <option value={t("")}>{t("Select one")}</option>
                {busninessCategories.map((busninessCategory) => {
                  return (
                    <option value={busninessCategory.id} key={busninessCategory.id}>
                      {busninessCategory.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {user.role.code === "SU" ? (
              <div className="mt-3">
                <label className="form-control-label">{t("Organization")}</label>
                <select
                  className="form-control"
                  placeholder={t("Organization")}
                  value={formCategory.organization}
                  onChange={(e) => {
                    setFormCategory((formCategory) => {
                      return { ...formCategory, organization: e.target.value };
                    });
                  }}
                >
                  <option value={t("")}>{t("Select one")}</option>
                  {organizations.map((organization) => {
                    return (
                      <option value={organization.id} key={organization.id}>
                        {organization.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              {t("Cancel")}
            </button>
            <button type="button" className="btn btn-primary" onClick={saveFormCategory}>
              {t("Add")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormCategory;
